import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import { AppWrapper } from '@/lib/components/app/AppWrapper';
import { ContentWrapper } from '@/lib/components/app/ContentWrapper';
import { useRouteLayout } from '@/lib/layouts';
import { PostMessageContextProvider } from '@/lib/contexts/PostMessageContext';
import { AssetsTableFilterContextProvider } from '@/lib/contexts/AssetsTableFilterContext/';
export default function App({ Component, pageProps }: AppProps) {
  const Layout = useRouteLayout();

  return (
    <PostMessageContextProvider>
      <AssetsTableFilterContextProvider>
        <AppWrapper>
          <ContentWrapper>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </ContentWrapper>
        </AppWrapper>
      </AssetsTableFilterContextProvider>
    </PostMessageContextProvider>
  );
}
