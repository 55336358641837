import { FunctionComponent, useMemo } from 'react';
import { useRouter } from 'next/router';
import { MyFleetLayout } from '@/lib/layouts/MyFleetLayout';
import { Layout } from '@/lib/layouts/types';
import { BlankLayout } from '@/lib/layouts/BlankLayout';

const RouteLayoutMap: Record<string, FunctionComponent> = {
  '/design': BlankLayout,
  '/playground': BlankLayout,
  '/visualize': BlankLayout,
  '/data-reports': BlankLayout,
  '/dashboards': BlankLayout,
  '/settings': BlankLayout,
  '/assets/details': BlankLayout,
  '/assets/add': BlankLayout,
  '/assets/[id]': MyFleetLayout,
  '/assets': MyFleetLayout,
  '/my-fleet': MyFleetLayout,
  '/': MyFleetLayout,
};

const RouteLayoutMapKeys = Object.keys(RouteLayoutMap);

export const useRouteLayout = (): Layout => {
  const { route } = useRouter();

  return useMemo(() => {
    const key = RouteLayoutMapKeys.find((path) => route.startsWith(path));

    if (key) {
      return RouteLayoutMap[key];
    }

    return BlankLayout;
  }, [route]);
};
