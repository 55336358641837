import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
};

export type Alert = Node & {
  __typename: 'Alert';
  assetKind: AssetKind;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  eventSource?: Maybe<EventSource | '%future added value'>;
  fleet: Fleet;
  /** The ID of the object */
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  /** Numeric value of the priority */
  numericPriority?: Maybe<Scalars['Int']>;
  priority?: Maybe<Priority | '%future added value'>;
  resolved: Scalars['Boolean'];
  rule?: Maybe<AlertRule>;
  source?: Maybe<BaseAsset>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AlertConnection = {
  __typename: 'AlertConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AlertEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AlertCounter = {
  __typename: 'AlertCounter';
  high?: Maybe<Scalars['Int']>;
  low?: Maybe<Scalars['Int']>;
  medium?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Alert` and its cursor. */
export type AlertEdge = {
  __typename: 'AlertEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Alert>;
};

export type AlertRule = Node & {
  __typename: 'AlertRule';
  active: Scalars['Boolean'];
  alerts: AlertConnection;
  assetKind: AssetKind;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  defaultPriority?: Maybe<Priority | '%future added value'>;
  definitionObject?: Maybe<Scalars['GenericScalar']>;
  description?: Maybe<Scalars['String']>;
  eventSource?: Maybe<EventSource | '%future added value'>;
  fleet: Fleet;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  raiseAlertOnError: Scalars['Boolean'];
  taskSet: TaskConnection;
  taskTemplate?: Maybe<TaskTemplate>;
  tasktemplateSet: TaskTemplateConnection;
  updatedAt: Scalars['DateTime'];
};


export type AlertRuleAlertsArgs = {
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Priority>;
  resolved?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};


export type AlertRuleTaskSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Scalars['ID']>;
  author?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TasksTaskStatusChoices>;
  title?: InputMaybe<Scalars['String']>;
};


export type AlertRuleTasktemplateSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Scalars['ID']>;
  author?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TasksTaskStatusChoices>;
  title?: InputMaybe<Scalars['String']>;
};

export type AlertRuleConnection = {
  __typename: 'AlertRuleConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AlertRuleEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AlertRule` and its cursor. */
export type AlertRuleEdge = {
  __typename: 'AlertRuleEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AlertRule>;
};

export type AssetDetailsLayout = Node & {
  __typename: 'AssetDetailsLayout';
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  target: AssetSchema;
  updatedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['GenericScalar']>;
};

export type AssetFilterValuesParams = {
  assetKindId: Scalars['ID'];
  field?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Scalars['GenericScalar']>;
  pagination?: InputMaybe<LimitOffsetPagination>;
  search?: InputMaybe<Scalars['String']>;
};

export type AssetKind = Node & {
  __typename: 'AssetKind';
  fileIcon?: Maybe<Scalars['String']>;
  fontIcon?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  layout?: Maybe<Scalars['GenericScalar']>;
  name: Scalars['String'];
  presentation?: Maybe<AssetPresentation>;
  schema?: Maybe<AssetSchema>;
  value: Scalars['String'];
};

export type AssetKindConnection = {
  __typename: 'AssetKindConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetKindEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetKind` and its cursor. */
export type AssetKindEdge = {
  __typename: 'AssetKindEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AssetKind>;
};

export type AssetPresentation = Node & {
  __typename: 'AssetPresentation';
  assetKind: AssetKind;
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  table?: Maybe<Scalars['GenericScalar']>;
  updatedAt: Scalars['DateTime'];
};

export type AssetPresentationConnection = {
  __typename: 'AssetPresentationConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetPresentationEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetPresentation` and its cursor. */
export type AssetPresentationEdge = {
  __typename: 'AssetPresentationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AssetPresentation>;
};

export type AssetSchema = Node & {
  __typename: 'AssetSchema';
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  target: AssetKind;
  updatedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['GenericScalar']>;
  version: Scalars['Int'];
};


export type AssetSchemaValueArgs = {
  base?: InputMaybe<Scalars['Boolean']>;
};

export type AssetSchemaConnection = {
  __typename: 'AssetSchemaConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetSchemaEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetSchema` and its cursor. */
export type AssetSchemaEdge = {
  __typename: 'AssetSchemaEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AssetSchema>;
};

/** An enumeration. */
export enum AssetsEdgeRoleChoices {
  /** Driver */
  Driver = 'DRIVER',
  /** Location */
  Location = 'LOCATION',
  /** Unknown */
  Unknown = 'UNKNOWN'
}

export type BaseAsset = Node & {
  __typename: 'BaseAsset';
  activeEdge?: Maybe<Edge>;
  assetKind: AssetKind;
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['GenericScalar']>;
  datapoints: DataPointConnection;
  edges: EdgeConnection;
  fleet: Fleet;
  /** The ID of the object */
  id: Scalars['ID'];
  /** This field includes any related information based on the externalFields configuration on the schema */
  relatedData?: Maybe<Scalars['GenericScalar']>;
  relatesFrom: BaseAssetRelationConnection;
  relatesTo: BaseAssetRelationConnection;
  /** This field includes any data from the `data` attribute thatneeded to be rendered in a special format (i.e.: as a link) */
  renderedData?: Maybe<Scalars['GenericScalar']>;
  taskSet: TaskConnection;
  tasktemplateSet: TaskTemplateConnection;
  uid: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
};


export type BaseAssetDatapointsArgs = {
  after?: InputMaybe<Scalars['String']>;
  asset?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['ID']>;
  timestamp_Range?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};


export type BaseAssetEdgesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<AssetsEdgeRoleChoices>;
  set?: InputMaybe<Scalars['ID']>;
};


export type BaseAssetRelatesFromArgs = {
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fleet?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};


export type BaseAssetRelatesToArgs = {
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fleet?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};


export type BaseAssetTaskSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Scalars['ID']>;
  author?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TasksTaskStatusChoices>;
  title?: InputMaybe<Scalars['String']>;
};


export type BaseAssetTasktemplateSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Scalars['ID']>;
  author?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TasksTaskStatusChoices>;
  title?: InputMaybe<Scalars['String']>;
};

export type BaseAssetConnection = {
  __typename: 'BaseAssetConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BaseAssetEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `BaseAsset` and its cursor. */
export type BaseAssetEdge = {
  __typename: 'BaseAssetEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BaseAsset>;
};

export type BaseAssetRelation = Node & {
  __typename: 'BaseAssetRelation';
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  sourceAsset: BaseAsset;
  targetAsset: BaseAsset;
  updatedAt: Scalars['DateTime'];
};

export type BaseAssetRelationConnection = {
  __typename: 'BaseAssetRelationConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BaseAssetRelationEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `BaseAssetRelation` and its cursor. */
export type BaseAssetRelationEdge = {
  __typename: 'BaseAssetRelationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BaseAssetRelation>;
};

export type ChartDashboard = Node & {
  __typename: 'ChartDashboard';
  cards: ChartDashboardCardConnection;
  createdAt: Scalars['DateTime'];
  fleet: Fleet;
  /** The ID of the object */
  id: Scalars['ID'];
  /** User-friendly name for the chart dashboard */
  name: Scalars['String'];
  snapshot: DataReportSnapshotConnection;
  updatedAt: Scalars['DateTime'];
  /**
   * If specified, the chart dashboard is assumed to be an external website and the specified
   *                     url points to the page to be rendered.
   *                     The website must allow iframe rendering.
   *                     Chart Dashboard Cards will not be used if this is specified.
   */
  url?: Maybe<Scalars['String']>;
};


export type ChartDashboardCardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dashboard?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type ChartDashboardSnapshotArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<ReportsDataReportSnapshotSourceChoices>;
  sourceId?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['ID']>;
};

export type ChartDashboardCard = Node & {
  __typename: 'ChartDashboardCard';
  charts?: Maybe<Array<Maybe<ChartDashboardCardEntry>>>;
  createdAt: Scalars['DateTime'];
  dashboard: ChartDashboard;
  /** The ID of the object */
  id: Scalars['ID'];
  position: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ChartDashboardCardConnection = {
  __typename: 'ChartDashboardCardConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ChartDashboardCardEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ChartDashboardCard` and its cursor. */
export type ChartDashboardCardEdge = {
  __typename: 'ChartDashboardCardEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ChartDashboardCard>;
};

export type ChartDashboardCardEntry = Node & {
  __typename: 'ChartDashboardCardEntry';
  card: ChartDashboardCard;
  configuration?: Maybe<Scalars['GenericScalar']>;
  /** The ID of the object */
  id: Scalars['ID'];
  position: Scalars['Int'];
  source: DataReportSnapshot;
};

export type ChartDashboardCardEntryConnection = {
  __typename: 'ChartDashboardCardEntryConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ChartDashboardCardEntryEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ChartDashboardCardEntry` and its cursor. */
export type ChartDashboardCardEntryEdge = {
  __typename: 'ChartDashboardCardEntryEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ChartDashboardCardEntry>;
};

export type ChartDashboardConnection = {
  __typename: 'ChartDashboardConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ChartDashboardEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ChartDashboard` and its cursor. */
export type ChartDashboardEdge = {
  __typename: 'ChartDashboardEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ChartDashboard>;
};

export type CreateAssetMutationInput = {
  assetKind: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  data: Scalars['GenericScalar'];
};

export type CreateAssetMutationPayload = {
  __typename: 'CreateAssetMutationPayload';
  asset?: Maybe<BaseAsset>;
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
};

export type CreateDataReportMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Scalars['GenericScalar']>;
  layout?: InputMaybe<Scalars['GenericScalar']>;
  name: Scalars['String'];
  sort?: InputMaybe<Scalars['GenericScalar']>;
  source: Scalars['String'];
  sourceId: Scalars['ID'];
};

/** Creates a data report */
export type CreateDataReportMutationPayload = {
  __typename: 'CreateDataReportMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  dataReport?: Maybe<DataReport>;
  errors?: Maybe<Array<ErrorType>>;
};

export type CreateTaskCommentMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment: Scalars['String'];
  taskId: Scalars['String'];
};

export type CreateTaskCommentMutationPayload = {
  __typename: 'CreateTaskCommentMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  taskComment?: Maybe<TaskComment>;
};

export type CreateTaskMutationInput = {
  asset?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Creates a new Task as defined on the django backend in tasks.models.Task */
export type CreateTaskMutationPayload = {
  __typename: 'CreateTaskMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  task?: Maybe<Task>;
};

export type DataPoint = Node & {
  __typename: 'DataPoint';
  asset: BaseAsset;
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['GenericScalar']>;
  /** The ID of the object */
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
  provider: DataPointProvider;
  timestamp: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DataPointConnection = {
  __typename: 'DataPointConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DataPointEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DataPoint` and its cursor. */
export type DataPointEdge = {
  __typename: 'DataPointEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DataPoint>;
};

export type DataPointProvider = Node & {
  __typename: 'DataPointProvider';
  createdAt: Scalars['DateTime'];
  datapoints: DataPointConnection;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type DataPointProviderDatapointsArgs = {
  after?: InputMaybe<Scalars['String']>;
  asset?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['ID']>;
  timestamp_Range?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type DataReport = Node & {
  __typename: 'DataReport';
  author?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdLabel?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['GenericScalar']>;
  /** The ID of the object */
  id: Scalars['ID'];
  layout?: Maybe<Scalars['GenericScalar']>;
  name: Scalars['String'];
  schedule?: Maybe<SchedulingTime | '%future added value'>;
  snapshots: DataReportSnapshotConnection;
  sort?: Maybe<Scalars['GenericScalar']>;
  source: ReportsDataReportSourceChoices | '%future added value';
  sourceId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};


export type DataReportSnapshotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<ReportsDataReportSnapshotSourceChoices>;
  sourceId?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['ID']>;
};

export type DataReportConnection = {
  __typename: 'DataReportConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DataReportEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DataReport` and its cursor. */
export type DataReportEdge = {
  __typename: 'DataReportEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DataReport>;
};

export type DataReportSnapshot = Node & {
  __typename: 'DataReportSnapshot';
  charts: ChartDashboardCardEntryConnection;
  createdAt: Scalars['DateTime'];
  dashboard?: Maybe<ChartDashboard>;
  dataStreamUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['GenericScalar']>;
  /** The ID of the object */
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['GenericScalar']>;
  name: Scalars['String'];
  rows: DataReportSnapshotRowConnection;
  sort?: Maybe<Scalars['GenericScalar']>;
  source: ReportsDataReportSnapshotSourceChoices | '%future added value';
  sourceId?: Maybe<Scalars['String']>;
  template?: Maybe<DataReport>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};


export type DataReportSnapshotChartsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  card?: InputMaybe<Scalars['ID']>;
  card_Dashboard?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type DataReportSnapshotRowsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  snapshot?: InputMaybe<Scalars['ID']>;
};

export type DataReportSnapshotConnection = {
  __typename: 'DataReportSnapshotConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DataReportSnapshotEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DataReportSnapshot` and its cursor. */
export type DataReportSnapshotEdge = {
  __typename: 'DataReportSnapshotEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DataReportSnapshot>;
};

export type DataReportSnapshotRow = Node & {
  __typename: 'DataReportSnapshotRow';
  data?: Maybe<Scalars['GenericScalar']>;
  /** The ID of the object */
  id: Scalars['ID'];
  snapshot: DataReportSnapshot;
};

export type DataReportSnapshotRowConnection = {
  __typename: 'DataReportSnapshotRowConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DataReportSnapshotRowEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DataReportSnapshotRow` and its cursor. */
export type DataReportSnapshotRowEdge = {
  __typename: 'DataReportSnapshotRowEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DataReportSnapshotRow>;
};

export type DeleteDataReportInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteDataReportPayload = {
  __typename: 'DeleteDataReportPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  dataReport?: Maybe<DataReport>;
  errors?: Maybe<Array<ErrorType>>;
};

export type DeleteRelatedAssetMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  sourceId: Scalars['ID'];
  targetId: Scalars['ID'];
};

/** deletes a relationship between two assets (non bi-directional) */
export type DeleteRelatedAssetMutationPayload = {
  __typename: 'DeleteRelatedAssetMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  relation?: Maybe<BaseAssetRelation>;
};

export type DeleteTaskCommentMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  commentId: Scalars['String'];
};

export type DeleteTaskCommentMutationPayload = {
  __typename: 'DeleteTaskCommentMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  taskComment?: Maybe<TaskComment>;
};

export type DeleteTaskMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteTaskMutationPayload = {
  __typename: 'DeleteTaskMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  task?: Maybe<Task>;
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename: 'DjangoDebug';
  /** Raise exceptions for this API query. */
  exceptions?: Maybe<Array<Maybe<DjangoDebugException>>>;
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single exception raised. */
export type DjangoDebugException = {
  __typename: 'DjangoDebugException';
  /** The class of the exception */
  excType: Scalars['String'];
  /** The message of the exception */
  message: Scalars['String'];
  /** The stack trace */
  stack: Scalars['String'];
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename: 'DjangoDebugSQL';
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String'];
  /** Duration of this database query in seconds. */
  duration: Scalars['Float'];
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']>;
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean'];
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']>;
  /** JSON encoded database query parameters. */
  params: Scalars['String'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']>;
  /** Start time of this database query. */
  startTime: Scalars['Float'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String'];
};

export type Edge = Node & {
  __typename: 'Edge';
  asset: BaseAsset;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  role: AssetsEdgeRoleChoices | '%future added value';
  updatedAt: Scalars['DateTime'];
};

export type EdgeConnection = {
  __typename: 'EdgeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EdgeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Edge` and its cursor. */
export type EdgeEdge = {
  __typename: 'EdgeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Edge>;
};

export type ErrorType = {
  __typename: 'ErrorType';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

/**
 *
 *     EventSource indicates what is triggering an evaluation of a rule.
 *
 */
export enum EventSource {
  Datapoint = 'datapoint',
  ManualEntry = 'manual_entry'
}

export type ExternalApp = Node & {
  __typename: 'ExternalApp';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type ExternalAppConnection = {
  __typename: 'ExternalAppConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExternalAppEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ExternalApp` and its cursor. */
export type ExternalAppEdge = {
  __typename: 'ExternalAppEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ExternalApp>;
};

export type Fleet = Node & {
  __typename: 'Fleet';
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  uid: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
};

export type InvokeDataReportInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type InvokeDataReportPayload = {
  __typename: 'InvokeDataReportPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  snapshot?: Maybe<DataReportSnapshot>;
};

export type LimitOffsetPagination = {
  limit: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
};

export type LoginMutation = {
  __typename: 'LoginMutation';
  token?: Maybe<Token>;
};

export type Mutation = {
  __typename: 'Mutation';
  _debug?: Maybe<DjangoDebug>;
  createAsset?: Maybe<CreateAssetMutationPayload>;
  /** Creates a data report */
  createDataReport?: Maybe<CreateDataReportMutationPayload>;
  /** Creates a new Task as defined on the django backend in tasks.models.Task */
  createTask?: Maybe<CreateTaskMutationPayload>;
  createTaskComment?: Maybe<CreateTaskCommentMutationPayload>;
  /** deletes a relationship between two assets (non bi-directional) */
  deleteAssetRelation?: Maybe<DeleteRelatedAssetMutationPayload>;
  deleteDataReport?: Maybe<DeleteDataReportPayload>;
  deleteTask?: Maybe<DeleteTaskMutationPayload>;
  deleteTaskComment?: Maybe<DeleteTaskCommentMutationPayload>;
  invokeDataReport?: Maybe<InvokeDataReportPayload>;
  login?: Maybe<LoginMutation>;
  patchAsset?: Maybe<PatchAssetDataMutationPayload>;
  /**
   * Updates a Task as defined on the django backend in tasks.models.Task
   * This performas a partial update of the task, meaning that only the field specified will be updated
   * If the task does not exist, this will simply throw an error to the GraphQL client
   */
  patchTask?: Maybe<PatchTaskMutationPayload>;
  /** Adds a rule or modifies one if an existing id is provided */
  putAlertRule?: Maybe<PutAlertRulePayload>;
  putTaskTemplate?: Maybe<PutTaskTemplateMutationPayload>;
  /** relates an asset to another asset (non bi-directional) */
  relateAsset?: Maybe<RelateAssetMutationPayload>;
  /** relates an asset to many other assets (non-bi-directional) */
  relateAssets?: Maybe<RelateAssetsMutationPayload>;
  /** Removes the scheduling for a data report */
  removeDataReportSnapshotSchedule?: Maybe<RemoveDataReportSnapshotSchedulePayload>;
  resolveAlerts?: Maybe<ResolveAlertsPayload>;
  /** Enables scheduling for a specific data report. */
  scheduleDataReportSnapshot?: Maybe<ScheduleDataReportSnapshotPayload>;
  updateAsset?: Maybe<UpdateAssetMutationPayload>;
  updateAssetDetailsLayoutSchema?: Maybe<UpdateAssetDetailsLayoutMutationPayload>;
  updateAssetKind?: Maybe<UpdateAssetKindMutationPayload>;
  updateAssetPresentationTableSchema?: Maybe<UpdateAssetPresentationTableSchemaPayload>;
  updateDataReport?: Maybe<UpdateDataReportMutationPayload>;
  updateSchema?: Maybe<UpdateAssetSchemaMutationPayload>;
  /**
   * Updates a Task as defined on the django backend in tasks.models.Task
   * This performas a full update of the task, meaning that all fields will be overwritten
   * If the task does not exist, this will simply throw an error to the GraphQL client
   */
  updateTask?: Maybe<UpdateTaskMutationPayload>;
};


export type MutationCreateAssetArgs = {
  input: CreateAssetMutationInput;
};


export type MutationCreateDataReportArgs = {
  input: CreateDataReportMutationInput;
};


export type MutationCreateTaskArgs = {
  input: CreateTaskMutationInput;
};


export type MutationCreateTaskCommentArgs = {
  input: CreateTaskCommentMutationInput;
};


export type MutationDeleteAssetRelationArgs = {
  input: DeleteRelatedAssetMutationInput;
};


export type MutationDeleteDataReportArgs = {
  input: DeleteDataReportInput;
};


export type MutationDeleteTaskArgs = {
  input: DeleteTaskMutationInput;
};


export type MutationDeleteTaskCommentArgs = {
  input: DeleteTaskCommentMutationInput;
};


export type MutationInvokeDataReportArgs = {
  input: InvokeDataReportInput;
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationPatchAssetArgs = {
  input: PatchAssetDataMutationInput;
};


export type MutationPatchTaskArgs = {
  input: PatchTaskMutationInput;
};


export type MutationPutAlertRuleArgs = {
  input: PutAlertRuleInput;
};


export type MutationPutTaskTemplateArgs = {
  input: PutTaskTemplateMutationInput;
};


export type MutationRelateAssetArgs = {
  input: RelateAssetMutationInput;
};


export type MutationRelateAssetsArgs = {
  input: RelateAssetsMutationInput;
};


export type MutationRemoveDataReportSnapshotScheduleArgs = {
  input: RemoveDataReportSnapshotScheduleInput;
};


export type MutationResolveAlertsArgs = {
  input: ResolveAlertsInput;
};


export type MutationScheduleDataReportSnapshotArgs = {
  input: ScheduleDataReportSnapshotInput;
};


export type MutationUpdateAssetArgs = {
  input: UpdateAssetMutationInput;
};


export type MutationUpdateAssetDetailsLayoutSchemaArgs = {
  input: UpdateAssetDetailsLayoutMutationInput;
};


export type MutationUpdateAssetKindArgs = {
  input: UpdateAssetKindMutationInput;
};


export type MutationUpdateAssetPresentationTableSchemaArgs = {
  input: UpdateAssetPresentationTableSchemaInput;
};


export type MutationUpdateDataReportArgs = {
  input: UpdateDataReportMutationInput;
};


export type MutationUpdateSchemaArgs = {
  input: UpdateAssetSchemaMutationInput;
};


export type MutationUpdateTaskArgs = {
  input: UpdateTaskMutationInput;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PatchAssetDataMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  field: Scalars['GenericScalar'];
  id: Scalars['ID'];
  value?: InputMaybe<Scalars['GenericScalar']>;
};

export type PatchAssetDataMutationPayload = {
  __typename: 'PatchAssetDataMutationPayload';
  asset?: Maybe<BaseAsset>;
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
};

export type PatchTaskMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  field: Scalars['GenericScalar'];
  id: Scalars['ID'];
  value?: InputMaybe<Scalars['GenericScalar']>;
};

/**
 * Updates a Task as defined on the django backend in tasks.models.Task
 * This performas a partial update of the task, meaning that only the field specified will be updated
 * If the task does not exist, this will simply throw an error to the GraphQL client
 */
export type PatchTaskMutationPayload = {
  __typename: 'PatchTaskMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  task?: Maybe<Task>;
};

/**
 *
 *     Priority is used to indicate the relevance/urgency of an alert
 *
 */
export enum Priority {
  High = 'high',
  Low = 'low',
  Medium = 'medium'
}

export type PutAlertRuleInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  assetKind: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['ID']>;
  defaultPriority?: InputMaybe<Priority | '%future added value'>;
  definitionObject: Scalars['GenericScalar'];
  description?: InputMaybe<Scalars['String']>;
  eventSource: EventSource | '%future added value';
  fleet?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  raiseAlertOnError?: InputMaybe<Scalars['Boolean']>;
  taskTemplate?: InputMaybe<Scalars['ID']>;
  taskTemplateData?: InputMaybe<Scalars['GenericScalar']>;
};

/** Adds a rule or modifies one if an existing id is provided */
export type PutAlertRulePayload = {
  __typename: 'PutAlertRulePayload';
  alertRule?: Maybe<AlertRule>;
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ErrorType>;
};

export type PutTaskTemplateMutationInput = {
  alertRule?: InputMaybe<Scalars['ID']>;
  asset?: InputMaybe<Scalars['ID']>;
  assignedTo?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  author?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PutTaskTemplateMutationPayload = {
  __typename: 'PutTaskTemplateMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ErrorType>;
  taskTemplate?: Maybe<TaskTemplate>;
};

export type Query = {
  __typename: 'Query';
  _debug?: Maybe<DjangoDebug>;
  alert?: Maybe<Alert>;
  alertCounter?: Maybe<AlertCounter>;
  alertRule?: Maybe<AlertRule>;
  alertRules?: Maybe<AlertRuleConnection>;
  alerts?: Maybe<AlertConnection>;
  allPresentations?: Maybe<AssetPresentationConnection>;
  allReports?: Maybe<ReportConnection>;
  asset?: Maybe<BaseAsset>;
  assetDetailsLayout?: Maybe<Scalars['GenericScalar']>;
  assetFilterValues?: Maybe<Array<Maybe<Scalars['String']>>>;
  assetKind?: Maybe<AssetKind>;
  assetKinds?: Maybe<AssetKindConnection>;
  assetSchema?: Maybe<AssetSchema>;
  assetSchemas?: Maybe<AssetSchemaConnection>;
  assets?: Maybe<BaseAssetConnection>;
  chartDashboard?: Maybe<ChartDashboard>;
  chartDashboardCardEntries?: Maybe<ChartDashboardCardEntryConnection>;
  chartDashboardCards?: Maybe<ChartDashboardCardConnection>;
  chartDashboards?: Maybe<ChartDashboardConnection>;
  dataReport?: Maybe<DataReport>;
  dataReportSnapshot?: Maybe<DataReportSnapshot>;
  dataReportSnapshotRows?: Maybe<DataReportSnapshotRowConnection>;
  dataReportSnapshots?: Maybe<DataReportSnapshotConnection>;
  dataReports?: Maybe<DataReportConnection>;
  datapoint?: Maybe<DataPoint>;
  datapoints?: Maybe<DataPointConnection>;
  externalApp?: Maybe<ExternalApp>;
  externalApps?: Maybe<ExternalAppConnection>;
  presentation?: Maybe<AssetPresentation>;
  report?: Maybe<Report>;
  reportFilterValues?: Maybe<Array<Maybe<Scalars['String']>>>;
  reportRows?: Maybe<ReportRowConnection>;
  task?: Maybe<Task>;
  taskComment?: Maybe<TaskComment>;
  taskComments?: Maybe<TaskCommentConnection>;
  taskTemplates?: Maybe<TaskTemplateConnection>;
  tasks?: Maybe<TaskConnection>;
  user?: Maybe<User>;
  users?: Maybe<UserConnection>;
};


export type QueryAlertArgs = {
  id: Scalars['ID'];
};


export type QueryAlertRuleArgs = {
  id: Scalars['ID'];
};


export type QueryAlertRulesArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['ID']>;
  defaultPriority?: InputMaybe<Priority>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


export type QueryAlertsArgs = {
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Priority>;
  resolved?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryAllPresentationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAllReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fleet?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAssetArgs = {
  id: Scalars['ID'];
};


export type QueryAssetDetailsLayoutArgs = {
  assetKindId: Scalars['ID'];
};


export type QueryAssetFilterValuesArgs = {
  input?: InputMaybe<AssetFilterValuesParams>;
};


export type QueryAssetKindArgs = {
  id: Scalars['ID'];
};


export type QueryAssetKindsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasSchema?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAssetSchemaArgs = {
  id: Scalars['ID'];
};


export type QueryAssetSchemasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fleet?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryChartDashboardArgs = {
  id: Scalars['ID'];
};


export type QueryChartDashboardCardEntriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  card?: InputMaybe<Scalars['ID']>;
  card_Dashboard?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryChartDashboardCardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dashboard?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryChartDashboardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  snapshot?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};


export type QueryDataReportArgs = {
  id: Scalars['ID'];
};


export type QueryDataReportSnapshotArgs = {
  id: Scalars['ID'];
};


export type QueryDataReportSnapshotRowsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  snapshot?: InputMaybe<Scalars['ID']>;
};


export type QueryDataReportSnapshotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<ReportsDataReportSnapshotSourceChoices>;
  sourceId?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['ID']>;
};


export type QueryDataReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryDatapointArgs = {
  id: Scalars['ID'];
};


export type QueryDatapointsArgs = {
  after?: InputMaybe<Scalars['String']>;
  asset?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['ID']>;
  timestamp_Range?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};


export type QueryExternalAppArgs = {
  id: Scalars['ID'];
};


export type QueryExternalAppsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};


export type QueryPresentationArgs = {
  id: Scalars['ID'];
};


export type QueryReportArgs = {
  id: Scalars['ID'];
};


export type QueryReportFilterValuesArgs = {
  input?: InputMaybe<ReportFilterValuesParams>;
};


export type QueryReportRowsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  report?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryTaskCommentArgs = {
  id: Scalars['ID'];
};


export type QueryTaskCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryTaskTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Scalars['ID']>;
  author?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TasksTaskStatusChoices>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryTasksArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Scalars['ID']>;
  author?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TasksTaskStatusChoices>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isStaff?: InputMaybe<Scalars['Boolean']>;
  isSuperuser?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  username?: InputMaybe<Scalars['String']>;
};

export type RelateAssetMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  sourceId: Scalars['ID'];
  targetId: Scalars['ID'];
};

/** relates an asset to another asset (non bi-directional) */
export type RelateAssetMutationPayload = {
  __typename: 'RelateAssetMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  relation?: Maybe<BaseAssetRelation>;
};

export type RelateAssetsMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  sourceId: Scalars['ID'];
  targetIds: Array<Scalars['ID']>;
};

/** relates an asset to many other assets (non-bi-directional) */
export type RelateAssetsMutationPayload = {
  __typename: 'RelateAssetsMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  relations?: Maybe<Array<BaseAssetRelation>>;
};

export type RemoveDataReportSnapshotScheduleInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  dataReportId: Scalars['ID'];
};

/** Removes the scheduling for a data report */
export type RemoveDataReportSnapshotSchedulePayload = {
  __typename: 'RemoveDataReportSnapshotSchedulePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  dataReport?: Maybe<DataReport>;
  errors?: Maybe<Array<ErrorType>>;
};

export type Report = Node & {
  __typename: 'Report';
  config?: Maybe<Scalars['GenericScalar']>;
  /** An object where the keys are the user-friendly labels and values are the dev friendly attribute names */
  fields?: Maybe<Scalars['GenericScalar']>;
  file: Scalars['String'];
  fileUri?: Maybe<Scalars['String']>;
  fleet?: Maybe<Fleet>;
  /** The ID of the object */
  id: Scalars['ID'];
  layout?: Maybe<Scalars['GenericScalar']>;
  name: Scalars['String'];
};

export type ReportConnection = {
  __typename: 'ReportConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReportEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Report` and its cursor. */
export type ReportEdge = {
  __typename: 'ReportEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Report>;
};

export type ReportFilterValuesParams = {
  field?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Scalars['GenericScalar']>;
  pagination?: InputMaybe<LimitOffsetPagination>;
  reportId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
};

export type ReportRow = Node & {
  __typename: 'ReportRow';
  data?: Maybe<Scalars['GenericScalar']>;
  /** The ID of the object */
  id: Scalars['ID'];
  report: Report;
};

export type ReportRowConnection = {
  __typename: 'ReportRowConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReportRowEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ReportRow` and its cursor. */
export type ReportRowEdge = {
  __typename: 'ReportRowEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ReportRow>;
};

/** An enumeration. */
export enum ReportsDataReportSnapshotSourceChoices {
  /** AI Generated */
  AiGenerated = 'AI_GENERATED',
  /** Assets */
  Assets = 'ASSETS',
  /** CSV */
  Csv = 'CSV'
}

/** An enumeration. */
export enum ReportsDataReportSourceChoices {
  /** AI Generated */
  AiGenerated = 'AI_GENERATED',
  /** Assets */
  Assets = 'ASSETS',
  /** CSV */
  Csv = 'CSV'
}

export type ResolveAlertsInput = {
  /** List of alert ids that will be marked as resolved */
  alertIds: Array<InputMaybe<Scalars['ID']>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type ResolveAlertsPayload = {
  __typename: 'ResolveAlertsPayload';
  alerts?: Maybe<Array<Maybe<Alert>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ScheduleDataReportSnapshotInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  dataReportId: Scalars['ID'];
  schedulingTime?: InputMaybe<SchedulingTime | '%future added value'>;
};

/** Enables scheduling for a specific data report. */
export type ScheduleDataReportSnapshotPayload = {
  __typename: 'ScheduleDataReportSnapshotPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  dataReport?: Maybe<DataReport>;
  errors?: Maybe<Array<ErrorType>>;
};

/**
 *
 *     Enums used to indicate that a task must be scheduled in one
 *     of the following patterns.
 *
 *     The values themselves are used by celery-beat to identify
 *     specific previously-created cronjobs.
 *
 *     NOTE: the integer represents the id in the Database
 *
 *     usage example:
 *         CrontabSchedule.objects.filter(id=SchedulingTime.DAILY.value).first()
 *
 */
export enum SchedulingTime {
  Daily = 'DAILY',
  FifteenthOfMonth = 'FIFTEENTH_OF_MONTH',
  FirstOfMonth = 'FIRST_OF_MONTH',
  Friday = 'FRIDAY',
  Hourly = 'HOURLY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type Task = Node & {
  __typename: 'Task';
  alertRule?: Maybe<AlertRule>;
  asset?: Maybe<BaseAsset>;
  assignedTo?: Maybe<Array<Maybe<User>>>;
  author?: Maybe<User>;
  comments: TaskCommentConnection;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  status: TasksTaskStatusChoices | '%future added value';
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type TaskCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type TaskComment = Node & {
  __typename: 'TaskComment';
  author: User;
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  task?: Maybe<Task>;
  updatedAt: Scalars['DateTime'];
};

export type TaskCommentConnection = {
  __typename: 'TaskCommentConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TaskCommentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TaskComment` and its cursor. */
export type TaskCommentEdge = {
  __typename: 'TaskCommentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TaskComment>;
};

export type TaskConnection = {
  __typename: 'TaskConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TaskEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Task` and its cursor. */
export type TaskEdge = {
  __typename: 'TaskEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Task>;
};

export type TaskTemplate = Node & {
  __typename: 'TaskTemplate';
  alertRule?: Maybe<AlertRule>;
  alertruleSet: AlertRuleConnection;
  asset?: Maybe<BaseAsset>;
  assignedTo?: Maybe<Array<Maybe<User>>>;
  author?: Maybe<User>;
  description: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  status: TasksTaskTemplateStatusChoices | '%future added value';
  tasks?: Maybe<Array<Maybe<Task>>>;
  title: Scalars['String'];
};


export type TaskTemplateAlertruleSetArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  assetKind?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['ID']>;
  defaultPriority?: InputMaybe<Priority>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
};

export type TaskTemplateConnection = {
  __typename: 'TaskTemplateConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TaskTemplateEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TaskTemplate` and its cursor. */
export type TaskTemplateEdge = {
  __typename: 'TaskTemplateEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TaskTemplate>;
};

/** An enumeration. */
export enum TasksTaskStatusChoices {
  /** Done */
  Done = 'DONE',
  /** In Progress */
  InProgress = 'IN_PROGRESS',
  /** Open */
  Open = 'OPEN'
}

/** An enumeration. */
export enum TasksTaskTemplateStatusChoices {
  /** Done */
  Done = 'DONE',
  /** In Progress */
  InProgress = 'IN_PROGRESS',
  /** Open */
  Open = 'OPEN'
}

export type Token = {
  __typename: 'Token';
  accessToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Int']>;
  expiresIn?: Maybe<Scalars['Int']>;
  scope?: Maybe<Scalars['String']>;
  tokenType?: Maybe<Scalars['String']>;
};

export type UpdateAssetDetailsLayoutMutationInput = {
  assetKindId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['GenericScalar']>;
};

export type UpdateAssetDetailsLayoutMutationPayload = {
  __typename: 'UpdateAssetDetailsLayoutMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  layout?: Maybe<AssetDetailsLayout>;
};

export type UpdateAssetKindMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  data: Scalars['GenericScalar'];
  id: Scalars['ID'];
};

export type UpdateAssetKindMutationPayload = {
  __typename: 'UpdateAssetKindMutationPayload';
  assetKind?: Maybe<AssetKind>;
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
};

export type UpdateAssetMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  data: Scalars['GenericScalar'];
  id: Scalars['ID'];
};

export type UpdateAssetMutationPayload = {
  __typename: 'UpdateAssetMutationPayload';
  asset?: Maybe<BaseAsset>;
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
};

export type UpdateAssetPresentationTableSchemaInput = {
  assetKindId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  schema?: InputMaybe<Scalars['GenericScalar']>;
};

export type UpdateAssetPresentationTableSchemaPayload = {
  __typename: 'UpdateAssetPresentationTableSchemaPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  schema?: Maybe<Scalars['GenericScalar']>;
};

export type UpdateAssetSchemaMutationInput = {
  /** ID of the AssetKind which schema is going to be updated */
  assetKindId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Schema value */
  data: Scalars['GenericScalar'];
};

export type UpdateAssetSchemaMutationPayload = {
  __typename: 'UpdateAssetSchemaMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  kind?: Maybe<AssetKind>;
};

export type UpdateDataReportMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Scalars['GenericScalar']>;
  id: Scalars['ID'];
  layout?: InputMaybe<Scalars['GenericScalar']>;
  name?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['GenericScalar']>;
};

export type UpdateDataReportMutationPayload = {
  __typename: 'UpdateDataReportMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  dataReport?: Maybe<DataReport>;
  errors?: Maybe<Array<ErrorType>>;
};

export type UpdateTaskMutationInput = {
  asset?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/**
 * Updates a Task as defined on the django backend in tasks.models.Task
 * This performas a full update of the task, meaning that all fields will be overwritten
 * If the task does not exist, this will simply throw an error to the GraphQL client
 */
export type UpdateTaskMutationPayload = {
  __typename: 'UpdateTaskMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  task?: Maybe<Task>;
};

export type User = Node & {
  __typename: 'User';
  email: Scalars['String'];
  firstName: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type UserConnection = {
  __typename: 'UserConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `User` and its cursor. */
export type UserEdge = {
  __typename: 'UserEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<User>;
};

export type UpdateAssetKindMutationVariables = Exact<{
  input: UpdateAssetKindMutationInput;
}>;


export type UpdateAssetKindMutation = { __typename: 'Mutation', updateAssetKind?: { __typename: 'UpdateAssetKindMutationPayload', assetKind?: { __typename: 'AssetKind', name: string, value: string, layout?: any | null, fontIcon?: string | null, fileIcon?: string | null, schema?: { __typename: 'AssetSchema', value?: any | null } | null, presentation?: { __typename: 'AssetPresentation', table?: any | null } | null } | null } | null };

export type UpdateAssetDetailsLayoutMutationVariables = Exact<{
  input: UpdateAssetDetailsLayoutMutationInput;
}>;


export type UpdateAssetDetailsLayoutMutation = { __typename: 'Mutation', updateAssetDetailsLayoutSchema?: { __typename: 'UpdateAssetDetailsLayoutMutationPayload', layout?: { __typename: 'AssetDetailsLayout', id: string, value?: any | null } | null } | null };

export type RelateAssetMutationVariables = Exact<{
  input: RelateAssetMutationInput;
}>;


export type RelateAssetMutation = { __typename: 'Mutation', relateAsset?: { __typename: 'RelateAssetMutationPayload', errors?: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> }> | null, relation?: { __typename: 'BaseAssetRelation', id: string, sourceAsset: { __typename: 'BaseAsset', uid: any }, targetAsset: { __typename: 'BaseAsset', uid: any } } | null } | null };

export type DeleteAssetRelationMutationVariables = Exact<{
  input: DeleteRelatedAssetMutationInput;
}>;


export type DeleteAssetRelationMutation = { __typename: 'Mutation', deleteAssetRelation?: { __typename: 'DeleteRelatedAssetMutationPayload', errors?: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> }> | null, relation?: { __typename: 'BaseAssetRelation', id: string, sourceAsset: { __typename: 'BaseAsset', uid: any }, targetAsset: { __typename: 'BaseAsset', uid: any } } | null } | null };

export type RelateAssetsMutationVariables = Exact<{
  input: RelateAssetsMutationInput;
}>;


export type RelateAssetsMutation = { __typename: 'Mutation', relateAssets?: { __typename: 'RelateAssetsMutationPayload', errors?: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> }> | null, relations?: Array<{ __typename: 'BaseAssetRelation', id: string, sourceAsset: { __typename: 'BaseAsset', uid: any }, targetAsset: { __typename: 'BaseAsset', uid: any } }> | null } | null };

export type UpdateAssetSchemaMutationVariables = Exact<{
  input: UpdateAssetSchemaMutationInput;
}>;


export type UpdateAssetSchemaMutation = { __typename: 'Mutation', updateSchema?: { __typename: 'UpdateAssetSchemaMutationPayload', kind?: { __typename: 'AssetKind', id: string, name: string, value: string, schema?: { __typename: 'AssetSchema', id: string, value?: any | null } | null } | null, errors?: Array<{ __typename: 'ErrorType', messages: Array<string> }> | null } | null };

export type AssetModificationFragment = { __typename: 'BaseAsset', id: string, uid: any, data?: any | null, relatedData?: any | null, assetKind: { __typename: 'AssetKind', id: string, name: string, value: string } };

export type CreateAssetMutationVariables = Exact<{
  input: CreateAssetMutationInput;
}>;


export type CreateAssetMutation = { __typename: 'Mutation', createAsset?: { __typename: 'CreateAssetMutationPayload', asset?: { __typename: 'BaseAsset', id: string, uid: any, data?: any | null, relatedData?: any | null, assetKind: { __typename: 'AssetKind', id: string, name: string, value: string } } | null, errors?: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> }> | null } | null };

export type UpdateAssetMutationVariables = Exact<{
  input: UpdateAssetMutationInput;
}>;


export type UpdateAssetMutation = { __typename: 'Mutation', updateAsset?: { __typename: 'UpdateAssetMutationPayload', asset?: { __typename: 'BaseAsset', id: string, uid: any, data?: any | null, relatedData?: any | null, assetKind: { __typename: 'AssetKind', id: string, name: string, value: string } } | null, errors?: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> }> | null } | null };

export type PatchAssetMutationVariables = Exact<{
  input: PatchAssetDataMutationInput;
}>;


export type PatchAssetMutation = { __typename: 'Mutation', patchAsset?: { __typename: 'PatchAssetDataMutationPayload', asset?: { __typename: 'BaseAsset', id: string, uid: any, data?: any | null, relatedData?: any | null, assetKind: { __typename: 'AssetKind', id: string, name: string, value: string } } | null, errors?: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> }> | null } | null };

export type UpdateAssetPresentationTableSchemaMutationVariables = Exact<{
  input: UpdateAssetPresentationTableSchemaInput;
}>;


export type UpdateAssetPresentationTableSchemaMutation = { __typename: 'Mutation', updateAssetPresentationTableSchema?: { __typename: 'UpdateAssetPresentationTableSchemaPayload', schema?: any | null, errors?: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> }> | null } | null };

export type CreateDataReportMutationVariables = Exact<{
  input: CreateDataReportMutationInput;
}>;


export type CreateDataReportMutation = { __typename: 'Mutation', createDataReport?: { __typename: 'CreateDataReportMutationPayload', errors?: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> }> | null, dataReport?: { __typename: 'DataReport', id: string, name: string, source: ReportsDataReportSourceChoices, sourceId: string, createdAt: any, updatedAt: any, description?: string | null, schedule?: SchedulingTime | null } | null } | null };

export type UpdateDataReportMutationVariables = Exact<{
  input: UpdateDataReportMutationInput;
}>;


export type UpdateDataReportMutation = { __typename: 'Mutation', updateDataReport?: { __typename: 'UpdateDataReportMutationPayload', errors?: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> }> | null, dataReport?: { __typename: 'DataReport', id: string, name: string, sort?: any | null, source: ReportsDataReportSourceChoices, sourceId: string, layout?: any | null, filters?: any | null, createdAt: any, updatedAt: any, description?: string | null } | null } | null };

export type DeleteTaskMutationVariables = Exact<{
  input: DeleteTaskMutationInput;
}>;


export type DeleteTaskMutation = { __typename: 'Mutation', deleteTask?: { __typename: 'DeleteTaskMutationPayload', task?: { __typename: 'Task', id: string, title: string, status: TasksTaskStatusChoices, description: string, author?: { __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null, assignedTo?: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null> | null } | null } | null };

export type CreateTaskMutationVariables = Exact<{
  input: CreateTaskMutationInput;
}>;


export type CreateTaskMutation = { __typename: 'Mutation', createTask?: { __typename: 'CreateTaskMutationPayload', task?: { __typename: 'Task', id: string, title: string, status: TasksTaskStatusChoices, description: string, author?: { __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null, assignedTo?: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null> | null } | null } | null };

export type PatchTaskMutationVariables = Exact<{
  input: PatchTaskMutationInput;
}>;


export type PatchTaskMutation = { __typename: 'Mutation', patchTask?: { __typename: 'PatchTaskMutationPayload', task?: { __typename: 'Task', id: string, title: string, status: TasksTaskStatusChoices, description: string, author?: { __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null, assignedTo?: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null> | null } | null } | null };

export type UpdateTaskMutationVariables = Exact<{
  input: UpdateTaskMutationInput;
}>;


export type UpdateTaskMutation = { __typename: 'Mutation', updateTask?: { __typename: 'UpdateTaskMutationPayload', task?: { __typename: 'Task', id: string, title: string, status: TasksTaskStatusChoices, description: string, author?: { __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null, assignedTo?: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null> | null } | null } | null };

export type AssetDetailsFragment = { __typename: 'BaseAsset', id: string, uid: any, data?: any | null, createdAt: any, updatedAt: any, relatedData?: any | null, assetKind: { __typename: 'AssetKind', id: string, name: string, value: string, layout?: any | null, schema?: { __typename: 'AssetSchema', id: string, version: number, createdAt: any, updatedAt: any, value?: any | null } | null, presentation?: { __typename: 'AssetPresentation', id: string, table?: any | null, createdAt: any, updatedAt: any } | null } };

export type AssetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssetQuery = { __typename: 'Query', asset?: { __typename: 'BaseAsset', relatedData?: any | null, id: string, uid: any, data?: any | null, createdAt: any, updatedAt: any, assetKind: { __typename: 'AssetKind', id: string, name: string, value: string, layout?: any | null, schema?: { __typename: 'AssetSchema', id: string, version: number, createdAt: any, updatedAt: any, value?: any | null } | null, presentation?: { __typename: 'AssetPresentation', id: string, table?: any | null, createdAt: any, updatedAt: any } | null } } | null };

export type GetAssetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAssetQuery = { __typename: 'Query', asset?: { __typename: 'BaseAsset', uid: any, data?: any | null, createdAt: any, updatedAt: any, fleet: { __typename: 'Fleet', id: string, uid: any, name: string, createdAt: any, updatedAt: any } } | null };

export type AssetKindsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  hasSchema?: InputMaybe<Scalars['Boolean']>;
}>;


export type AssetKindsQuery = { __typename: 'Query', assetKinds?: { __typename: 'AssetKindConnection', pageInfo: { __typename: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename: 'AssetKindEdge', node?: { __typename: 'AssetKind', id: string, name: string, value: string, fontIcon?: string | null, fileIcon?: string | null, layout?: any | null, presentation?: { __typename: 'AssetPresentation', id: string, table?: any | null, createdAt: any, updatedAt: any } | null, schema?: { __typename: 'AssetSchema', id: string, version: number, createdAt: any, updatedAt: any, value?: any | null } | null } | null } | null> } | null };

export type AssetKindQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssetKindQuery = { __typename: 'Query', assetKind?: { __typename: 'AssetKind', id: string, name: string, value: string, fontIcon?: string | null, fileIcon?: string | null, layout?: any | null, presentation?: { __typename: 'AssetPresentation', id: string, table?: any | null, createdAt: any, updatedAt: any } | null, schema?: { __typename: 'AssetSchema', id: string, version: number, createdAt: any, updatedAt: any, value?: any | null } | null } | null };

export type AssetsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
}>;


export type AssetsQuery = { __typename: 'Query', assets?: { __typename: 'BaseAssetConnection', totalCount?: number | null, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename: 'BaseAssetEdge', cursor: string, node?: { __typename: 'BaseAsset', id: string, createdAt: any, updatedAt: any, data?: any | null, relatedData?: any | null, relatesTo: { __typename: 'BaseAssetRelationConnection', edges: Array<{ __typename: 'BaseAssetRelationEdge', node?: { __typename: 'BaseAssetRelation', targetAsset: { __typename: 'BaseAsset', id: string, data?: any | null } } | null } | null> } } | null } | null> } | null };

export type GetAssetsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  fleet?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetAssetsQuery = { __typename: 'Query', assets?: { __typename: 'BaseAssetConnection', edges: Array<{ __typename: 'BaseAssetEdge', node?: { __typename: 'BaseAsset', id: string, uid: any, data?: any | null, createdAt: any, updatedAt: any, fleet: { __typename: 'Fleet', id: string, uid: any, name: string } } | null } | null> } | null };

export type ChartDashboradFragmentFragment = { __typename: 'ChartDashboard', id: string, name: string, url?: string | null, createdAt: any, updatedAt: any };

export type ChartDashboardsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type ChartDashboardsQuery = { __typename: 'Query', chartDashboards?: { __typename: 'ChartDashboardConnection', pageInfo: { __typename: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename: 'ChartDashboardEdge', node?: { __typename: 'ChartDashboard', id: string, name: string, url?: string | null, createdAt: any, updatedAt: any } | null } | null> } | null };

export type ChartDashboardQueryVariables = Exact<{
  id: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type ChartDashboardQuery = { __typename: 'Query', chartDashboard?: { __typename: 'ChartDashboard', id: string, name: string, url?: string | null, createdAt: any, updatedAt: any, cards: { __typename: 'ChartDashboardCardConnection', pageInfo: { __typename: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename: 'ChartDashboardCardEdge', node?: { __typename: 'ChartDashboardCard', id: string, title?: string | null, position: number, charts?: Array<{ __typename: 'ChartDashboardCardEntry', id: string, configuration?: any | null, source: { __typename: 'DataReportSnapshot', id: string, name: string, layout?: any | null, dataStreamUrl?: string | null } } | null> | null } | null } | null> } } | null };

export type ChartDashboardCardsQueryVariables = Exact<{
  dashboardId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type ChartDashboardCardsQuery = { __typename: 'Query', chartDashboardCards?: { __typename: 'ChartDashboardCardConnection', totalCount?: number | null, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename: 'ChartDashboardCardEdge', node?: { __typename: 'ChartDashboardCard', id: string, title?: string | null, position: number, createdAt: any, updatedAt: any, charts?: Array<{ __typename: 'ChartDashboardCardEntry', id: string, position: number, configuration?: any | null, source: { __typename: 'DataReportSnapshot', id: string, name: string, layout?: any | null, dataStreamUrl?: string | null } } | null> | null } | null } | null> } | null };

export type DataReportsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}>;


export type DataReportsQuery = { __typename: 'Query', dataReports?: { __typename: 'DataReportConnection', totalCount?: number | null, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename: 'DataReportEdge', node?: { __typename: 'DataReport', id: string, name: string, source: ReportsDataReportSourceChoices, sourceId: string, createdAt: any, updatedAt: any, user?: { __typename: 'User', email: string } | null, snapshots: { __typename: 'DataReportSnapshotConnection', edges: Array<{ __typename: 'DataReportSnapshotEdge', node?: { __typename: 'DataReportSnapshot', createdAt: any } | null } | null> } } | null } | null> } | null };

export type DataReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DataReportQuery = { __typename: 'Query', dataReport?: { __typename: 'DataReport', id: string, name: string, source: ReportsDataReportSourceChoices, sourceId: string, sort?: any | null, layout?: any | null, filters?: any | null, createdAt: any, updatedAt: any, schedule?: SchedulingTime | null, description?: string | null, user?: { __typename: 'User', email: string } | null, snapshots: { __typename: 'DataReportSnapshotConnection', edges: Array<{ __typename: 'DataReportSnapshotEdge', node?: { __typename: 'DataReportSnapshot', createdAt: any } | null } | null> } } | null };

export type DataReportSnapshotQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DataReportSnapshotQuery = { __typename: 'Query', dataReportSnapshot?: { __typename: 'DataReportSnapshot', id: string, name: string, source: ReportsDataReportSnapshotSourceChoices, sourceId?: string | null, downloadUrl?: string | null, dataStreamUrl?: string | null, createdAt: any, filters?: any | null, layout?: any | null, sort?: any | null, description?: string | null, label?: string | null, url?: string | null, template?: { __typename: 'DataReport', id: string } | null, user?: { __typename: 'User', email: string } | null, dashboard?: { __typename: 'ChartDashboard', id: string, name: string, createdAt: any, updatedAt: any } | null } | null };

export type DataReportSnapshotRowsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  snapshotId: Scalars['ID'];
}>;


export type DataReportSnapshotRowsQuery = { __typename: 'Query', dataReportSnapshotRows?: { __typename: 'DataReportSnapshotRowConnection', totalCount?: number | null, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename: 'DataReportSnapshotRowEdge', node?: { __typename: 'DataReportSnapshotRow', id: string, data?: any | null } | null } | null> } | null };

export type DataReportSnapshotsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['ID']>;
}>;


export type DataReportSnapshotsQuery = { __typename: 'Query', dataReportSnapshots?: { __typename: 'DataReportSnapshotConnection', totalCount?: number | null, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename: 'DataReportSnapshotEdge', node?: { __typename: 'DataReportSnapshot', id: string, name: string, source: ReportsDataReportSnapshotSourceChoices, sourceId?: string | null, createdAt: any, label?: string | null, url?: string | null } | null } | null> } | null };

export type InvokeDataReportMutationVariables = Exact<{
  input: InvokeDataReportInput;
}>;


export type InvokeDataReportMutation = { __typename: 'Mutation', invokeDataReport?: { __typename: 'InvokeDataReportPayload', errors?: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> }> | null, snapshot?: { __typename: 'DataReportSnapshot', id: string, name: string, createdAt: any } | null } | null };

export type DeleteDataReportMutationVariables = Exact<{
  input: DeleteDataReportInput;
}>;


export type DeleteDataReportMutation = { __typename: 'Mutation', deleteDataReport?: { __typename: 'DeleteDataReportPayload', errors?: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> }> | null } | null };

export type GetDatapointQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDatapointQuery = { __typename: 'Query', datapoint?: { __typename: 'DataPoint', id: string, kind?: string | null, data?: any | null, createdAt: any, updatedAt: any } | null };

export type DataPointsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  asset?: InputMaybe<Scalars['ID']>;
  provider?: InputMaybe<Scalars['ID']>;
}>;


export type DataPointsQuery = { __typename: 'Query', datapoints?: { __typename: 'DataPointConnection', pageInfo: { __typename: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename: 'DataPointEdge', node?: { __typename: 'DataPoint', id: string, data?: any | null, timestamp: any, provider: { __typename: 'DataPointProvider', id: string, name: string } } | null } | null> } | null };

export type GetDatapointsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetDatapointsQuery = { __typename: 'Query', datapoints?: { __typename: 'DataPointConnection', edges: Array<{ __typename: 'DataPointEdge', node?: { __typename: 'DataPoint', id: string, data?: any | null, timestamp: any, kind?: string | null, createdAt: any, updatedAt: any } | null } | null> } | null };

export type AssetFilterValuesQueryVariables = Exact<{
  input?: InputMaybe<AssetFilterValuesParams>;
}>;


export type AssetFilterValuesQuery = { __typename: 'Query', assetFilterValues?: Array<string | null> | null };

export type ErrorsFragment = { __typename: 'ErrorType', field: string, messages: Array<string> };

export type PaginationFragment = { __typename: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null };

export type BasicAssetListingInfoFragment = { __typename: 'BaseAsset', id: string, createdAt: any, updatedAt: any, data?: any | null, relatedData?: any | null, relatesTo: { __typename: 'BaseAssetRelationConnection', edges: Array<{ __typename: 'BaseAssetRelationEdge', node?: { __typename: 'BaseAssetRelation', targetAsset: { __typename: 'BaseAsset', id: string, data?: any | null } } | null } | null> } };

export type AssetSchemaFieldsFragment = { __typename: 'AssetSchema', id: string, version: number, createdAt: any, updatedAt: any, value?: any | null };

export type PresentationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PresentationQuery = { __typename: 'Query', presentation?: { __typename: 'AssetPresentation', id: string, createdAt: any, updatedAt: any, table?: any | null, assetKind: { __typename: 'AssetKind', id: string, name: string, value: string, layout?: any | null, schema?: { __typename: 'AssetSchema', id: string, value?: any | null, target: { __typename: 'AssetKind', id: string, name: string, value: string } } | null } } | null };

export type PresentationsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type PresentationsQuery = { __typename: 'Query', allPresentations?: { __typename: 'AssetPresentationConnection', edges: Array<{ __typename: 'AssetPresentationEdge', node?: { __typename: 'AssetPresentation', id: string, table?: any | null, assetKind: { __typename: 'AssetKind', id: string, name: string, value: string, layout?: any | null, schema?: { __typename: 'AssetSchema', id: string, value?: any | null, target: { __typename: 'AssetKind', id: string, name: string, value: string } } | null } } | null } | null> } | null };

export type ReportsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
}>;


export type ReportsQuery = { __typename: 'Query', allReports?: { __typename: 'ReportConnection', pageInfo: { __typename: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename: 'ReportEdge', node?: { __typename: 'Report', id: string, name: string, file: string, fileUri?: string | null } | null } | null> } | null };

export type ReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReportQuery = { __typename: 'Query', report?: { __typename: 'Report', id: string, name: string, file: string, fileUri?: string | null, config?: any | null, layout?: any | null } | null };

export type ReportRowsQueryVariables = Exact<{
  reportId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
}>;


export type ReportRowsQuery = { __typename: 'Query', reportRows?: { __typename: 'ReportRowConnection', totalCount?: number | null, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename: 'ReportRowEdge', node?: { __typename: 'ReportRow', id: string, data?: any | null } | null } | null> } | null };

export type ReportFilterValuesQueryVariables = Exact<{
  params?: InputMaybe<ReportFilterValuesParams>;
}>;


export type ReportFilterValuesQuery = { __typename: 'Query', reportFilterValues?: Array<string | null> | null, _debug?: { __typename: 'DjangoDebug', sql?: Array<{ __typename: 'DjangoDebugSQL', sql?: string | null } | null> | null, exceptions?: Array<{ __typename: 'DjangoDebugException', stack: string, message: string, excType: string } | null> | null } | null };

export type ScheduleSnapshotMutationVariables = Exact<{
  input: ScheduleDataReportSnapshotInput;
}>;


export type ScheduleSnapshotMutation = { __typename: 'Mutation', scheduleDataReportSnapshot?: { __typename: 'ScheduleDataReportSnapshotPayload', dataReport?: { __typename: 'DataReport', id: string, name: string, schedule?: SchedulingTime | null } | null } | null };

export type RemoveScheduleMutationVariables = Exact<{
  input: RemoveDataReportSnapshotScheduleInput;
}>;


export type RemoveScheduleMutation = { __typename: 'Mutation', removeDataReportSnapshotSchedule?: { __typename: 'RemoveDataReportSnapshotSchedulePayload', dataReport?: { __typename: 'DataReport', id: string, name: string, schedule?: SchedulingTime | null } | null } | null };

export type AssetSchemaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssetSchemaQuery = { __typename: 'Query', assetSchema?: { __typename: 'AssetSchema', id: string, version: number, createdAt: any, updatedAt: any, value?: any | null } | null };

export type AssetSchemasQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type AssetSchemasQuery = { __typename: 'Query', assetSchemas?: { __typename: 'AssetSchemaConnection', pageInfo: { __typename: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasPreviousPage: boolean, hasNextPage: boolean }, edges: Array<{ __typename: 'AssetSchemaEdge', node?: { __typename: 'AssetSchema', id: string, version: number, createdAt: any, updatedAt: any, value?: any | null } | null } | null> } | null };

export type TaskFragmentFragment = { __typename: 'Task', id: string, title: string, status: TasksTaskStatusChoices, description: string, author?: { __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null, assignedTo?: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null> | null };

export type TaskQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskQuery = { __typename: 'Query', task?: { __typename: 'Task', id: string, title: string, status: TasksTaskStatusChoices, description: string, author?: { __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null, assignedTo?: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null> | null } | null };

export type TasksQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TasksQuery = { __typename: 'Query', tasks?: { __typename: 'TaskConnection', edges: Array<{ __typename: 'TaskEdge', node?: { __typename: 'Task', id: string, title: string, status: TasksTaskStatusChoices, description: string, author?: { __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null, assignedTo?: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null> | null } | null } | null> } | null };

export type TaskCommentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskCommentQuery = { __typename: 'Query', taskComment?: { __typename: 'TaskComment', comment: string, task?: { __typename: 'Task', id: string, title: string, status: TasksTaskStatusChoices, description: string, author?: { __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null, assignedTo?: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null> | null } | null } | null };

export type TaskCommentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskCommentsQuery = { __typename: 'Query', taskComments?: { __typename: 'TaskCommentConnection', edges: Array<{ __typename: 'TaskCommentEdge', node?: { __typename: 'TaskComment', comment: string, task?: { __typename: 'Task', id: string, title: string, status: TasksTaskStatusChoices, description: string, author?: { __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null, assignedTo?: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null> | null } | null } | null } | null> } | null };

export type UserFragmentFragment = { __typename: 'User', id: string, email: string, firstName: string, lastName: string };

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = { __typename: 'Query', user?: { __typename: 'User', id: string, email: string, firstName: string, lastName: string } | null };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename: 'Query', users?: { __typename: 'UserConnection', edges: Array<{ __typename: 'UserEdge', node?: { __typename: 'User', id: string, email: string, firstName: string, lastName: string } | null } | null> } | null };

export const AssetModificationFragmentDoc = gql`
    fragment AssetModification on BaseAsset {
  id
  uid
  data
  relatedData
  assetKind {
    id
    name
    value
  }
}
    `;
export const AssetDetailsFragmentDoc = gql`
    fragment AssetDetails on BaseAsset {
  id
  uid
  data
  createdAt
  updatedAt
  relatedData
  assetKind {
    id
    name
    value
    schema {
      id
      version
      createdAt
      updatedAt
      value
    }
    layout
    presentation {
      id
      table
      createdAt
      updatedAt
    }
  }
}
    `;
export const ChartDashboradFragmentFragmentDoc = gql`
    fragment ChartDashboradFragment on ChartDashboard {
  id
  name
  url
  createdAt
  updatedAt
}
    `;
export const ErrorsFragmentDoc = gql`
    fragment Errors on ErrorType {
  field
  messages
}
    `;
export const PaginationFragmentDoc = gql`
    fragment Pagination on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const BasicAssetListingInfoFragmentDoc = gql`
    fragment BasicAssetListingInfo on BaseAsset {
  id
  createdAt
  updatedAt
  data
  relatedData
  relatesTo {
    edges {
      node {
        targetAsset {
          id
          data
        }
      }
    }
  }
}
    `;
export const AssetSchemaFieldsFragmentDoc = gql`
    fragment AssetSchemaFields on AssetSchema {
  id
  version
  createdAt
  updatedAt
  value
}
    `;
export const TaskFragmentFragmentDoc = gql`
    fragment TaskFragment on Task {
  id
  title
  status
  description
  author {
    id
    firstName
    lastName
    email
  }
  assignedTo {
    id
    firstName
    lastName
    email
  }
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  email
  firstName
  lastName
}
    `;
export const UpdateAssetKindDocument = gql`
    mutation UpdateAssetKind($input: UpdateAssetKindMutationInput!) {
  updateAssetKind(input: $input) {
    assetKind {
      name
      value
      schema {
        value
      }
      presentation {
        table
      }
      layout
      fontIcon
      fileIcon
    }
  }
}
    `;
export type UpdateAssetKindMutationFn = Apollo.MutationFunction<UpdateAssetKindMutation, UpdateAssetKindMutationVariables>;

/**
 * __useUpdateAssetKindMutation__
 *
 * To run a mutation, you first call `useUpdateAssetKindMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetKindMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetKindMutation, { data, loading, error }] = useUpdateAssetKindMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetKindMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssetKindMutation, UpdateAssetKindMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssetKindMutation, UpdateAssetKindMutationVariables>(UpdateAssetKindDocument, options);
      }
export type UpdateAssetKindMutationHookResult = ReturnType<typeof useUpdateAssetKindMutation>;
export type UpdateAssetKindMutationResult = Apollo.MutationResult<UpdateAssetKindMutation>;
export type UpdateAssetKindMutationOptions = Apollo.BaseMutationOptions<UpdateAssetKindMutation, UpdateAssetKindMutationVariables>;
export const UpdateAssetDetailsLayoutDocument = gql`
    mutation UpdateAssetDetailsLayout($input: UpdateAssetDetailsLayoutMutationInput!) {
  updateAssetDetailsLayoutSchema(input: $input) {
    layout {
      id
      value
    }
  }
}
    `;
export type UpdateAssetDetailsLayoutMutationFn = Apollo.MutationFunction<UpdateAssetDetailsLayoutMutation, UpdateAssetDetailsLayoutMutationVariables>;

/**
 * __useUpdateAssetDetailsLayoutMutation__
 *
 * To run a mutation, you first call `useUpdateAssetDetailsLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetDetailsLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetDetailsLayoutMutation, { data, loading, error }] = useUpdateAssetDetailsLayoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetDetailsLayoutMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssetDetailsLayoutMutation, UpdateAssetDetailsLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssetDetailsLayoutMutation, UpdateAssetDetailsLayoutMutationVariables>(UpdateAssetDetailsLayoutDocument, options);
      }
export type UpdateAssetDetailsLayoutMutationHookResult = ReturnType<typeof useUpdateAssetDetailsLayoutMutation>;
export type UpdateAssetDetailsLayoutMutationResult = Apollo.MutationResult<UpdateAssetDetailsLayoutMutation>;
export type UpdateAssetDetailsLayoutMutationOptions = Apollo.BaseMutationOptions<UpdateAssetDetailsLayoutMutation, UpdateAssetDetailsLayoutMutationVariables>;
export const RelateAssetDocument = gql`
    mutation RelateAsset($input: RelateAssetMutationInput!) {
  relateAsset(input: $input) {
    errors {
      field
      messages
    }
    relation {
      id
      sourceAsset {
        uid
      }
      targetAsset {
        uid
      }
    }
  }
}
    `;
export type RelateAssetMutationFn = Apollo.MutationFunction<RelateAssetMutation, RelateAssetMutationVariables>;

/**
 * __useRelateAssetMutation__
 *
 * To run a mutation, you first call `useRelateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relateAssetMutation, { data, loading, error }] = useRelateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRelateAssetMutation(baseOptions?: Apollo.MutationHookOptions<RelateAssetMutation, RelateAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RelateAssetMutation, RelateAssetMutationVariables>(RelateAssetDocument, options);
      }
export type RelateAssetMutationHookResult = ReturnType<typeof useRelateAssetMutation>;
export type RelateAssetMutationResult = Apollo.MutationResult<RelateAssetMutation>;
export type RelateAssetMutationOptions = Apollo.BaseMutationOptions<RelateAssetMutation, RelateAssetMutationVariables>;
export const DeleteAssetRelationDocument = gql`
    mutation DeleteAssetRelation($input: DeleteRelatedAssetMutationInput!) {
  deleteAssetRelation(input: $input) {
    errors {
      field
      messages
    }
    relation {
      id
      sourceAsset {
        uid
      }
      targetAsset {
        uid
      }
    }
  }
}
    `;
export type DeleteAssetRelationMutationFn = Apollo.MutationFunction<DeleteAssetRelationMutation, DeleteAssetRelationMutationVariables>;

/**
 * __useDeleteAssetRelationMutation__
 *
 * To run a mutation, you first call `useDeleteAssetRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetRelationMutation, { data, loading, error }] = useDeleteAssetRelationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetRelationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssetRelationMutation, DeleteAssetRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAssetRelationMutation, DeleteAssetRelationMutationVariables>(DeleteAssetRelationDocument, options);
      }
export type DeleteAssetRelationMutationHookResult = ReturnType<typeof useDeleteAssetRelationMutation>;
export type DeleteAssetRelationMutationResult = Apollo.MutationResult<DeleteAssetRelationMutation>;
export type DeleteAssetRelationMutationOptions = Apollo.BaseMutationOptions<DeleteAssetRelationMutation, DeleteAssetRelationMutationVariables>;
export const RelateAssetsDocument = gql`
    mutation RelateAssets($input: RelateAssetsMutationInput!) {
  relateAssets(input: $input) {
    errors {
      field
      messages
    }
    relations {
      id
      sourceAsset {
        uid
      }
      targetAsset {
        uid
      }
    }
  }
}
    `;
export type RelateAssetsMutationFn = Apollo.MutationFunction<RelateAssetsMutation, RelateAssetsMutationVariables>;

/**
 * __useRelateAssetsMutation__
 *
 * To run a mutation, you first call `useRelateAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelateAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relateAssetsMutation, { data, loading, error }] = useRelateAssetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRelateAssetsMutation(baseOptions?: Apollo.MutationHookOptions<RelateAssetsMutation, RelateAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RelateAssetsMutation, RelateAssetsMutationVariables>(RelateAssetsDocument, options);
      }
export type RelateAssetsMutationHookResult = ReturnType<typeof useRelateAssetsMutation>;
export type RelateAssetsMutationResult = Apollo.MutationResult<RelateAssetsMutation>;
export type RelateAssetsMutationOptions = Apollo.BaseMutationOptions<RelateAssetsMutation, RelateAssetsMutationVariables>;
export const UpdateAssetSchemaDocument = gql`
    mutation UpdateAssetSchema($input: UpdateAssetSchemaMutationInput!) {
  updateSchema(input: $input) {
    kind {
      id
      name
      value
      schema {
        id
        value
      }
    }
    errors {
      messages
    }
  }
}
    `;
export type UpdateAssetSchemaMutationFn = Apollo.MutationFunction<UpdateAssetSchemaMutation, UpdateAssetSchemaMutationVariables>;

/**
 * __useUpdateAssetSchemaMutation__
 *
 * To run a mutation, you first call `useUpdateAssetSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetSchemaMutation, { data, loading, error }] = useUpdateAssetSchemaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetSchemaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssetSchemaMutation, UpdateAssetSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssetSchemaMutation, UpdateAssetSchemaMutationVariables>(UpdateAssetSchemaDocument, options);
      }
export type UpdateAssetSchemaMutationHookResult = ReturnType<typeof useUpdateAssetSchemaMutation>;
export type UpdateAssetSchemaMutationResult = Apollo.MutationResult<UpdateAssetSchemaMutation>;
export type UpdateAssetSchemaMutationOptions = Apollo.BaseMutationOptions<UpdateAssetSchemaMutation, UpdateAssetSchemaMutationVariables>;
export const CreateAssetDocument = gql`
    mutation CreateAsset($input: CreateAssetMutationInput!) {
  createAsset(input: $input) {
    asset {
      ...AssetModification
    }
    errors {
      ...Errors
    }
  }
}
    ${AssetModificationFragmentDoc}
${ErrorsFragmentDoc}`;
export type CreateAssetMutationFn = Apollo.MutationFunction<CreateAssetMutation, CreateAssetMutationVariables>;

/**
 * __useCreateAssetMutation__
 *
 * To run a mutation, you first call `useCreateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetMutation, { data, loading, error }] = useCreateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssetMutation, CreateAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(CreateAssetDocument, options);
      }
export type CreateAssetMutationHookResult = ReturnType<typeof useCreateAssetMutation>;
export type CreateAssetMutationResult = Apollo.MutationResult<CreateAssetMutation>;
export type CreateAssetMutationOptions = Apollo.BaseMutationOptions<CreateAssetMutation, CreateAssetMutationVariables>;
export const UpdateAssetDocument = gql`
    mutation UpdateAsset($input: UpdateAssetMutationInput!) {
  updateAsset(input: $input) {
    asset {
      ...AssetModification
    }
    errors {
      ...Errors
    }
  }
}
    ${AssetModificationFragmentDoc}
${ErrorsFragmentDoc}`;
export type UpdateAssetMutationFn = Apollo.MutationFunction<UpdateAssetMutation, UpdateAssetMutationVariables>;

/**
 * __useUpdateAssetMutation__
 *
 * To run a mutation, you first call `useUpdateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetMutation, { data, loading, error }] = useUpdateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssetMutation, UpdateAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssetMutation, UpdateAssetMutationVariables>(UpdateAssetDocument, options);
      }
export type UpdateAssetMutationHookResult = ReturnType<typeof useUpdateAssetMutation>;
export type UpdateAssetMutationResult = Apollo.MutationResult<UpdateAssetMutation>;
export type UpdateAssetMutationOptions = Apollo.BaseMutationOptions<UpdateAssetMutation, UpdateAssetMutationVariables>;
export const PatchAssetDocument = gql`
    mutation PatchAsset($input: PatchAssetDataMutationInput!) {
  patchAsset(input: $input) {
    asset {
      ...AssetModification
    }
    errors {
      ...Errors
    }
  }
}
    ${AssetModificationFragmentDoc}
${ErrorsFragmentDoc}`;
export type PatchAssetMutationFn = Apollo.MutationFunction<PatchAssetMutation, PatchAssetMutationVariables>;

/**
 * __usePatchAssetMutation__
 *
 * To run a mutation, you first call `usePatchAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchAssetMutation, { data, loading, error }] = usePatchAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatchAssetMutation(baseOptions?: Apollo.MutationHookOptions<PatchAssetMutation, PatchAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchAssetMutation, PatchAssetMutationVariables>(PatchAssetDocument, options);
      }
export type PatchAssetMutationHookResult = ReturnType<typeof usePatchAssetMutation>;
export type PatchAssetMutationResult = Apollo.MutationResult<PatchAssetMutation>;
export type PatchAssetMutationOptions = Apollo.BaseMutationOptions<PatchAssetMutation, PatchAssetMutationVariables>;
export const UpdateAssetPresentationTableSchemaDocument = gql`
    mutation UpdateAssetPresentationTableSchema($input: UpdateAssetPresentationTableSchemaInput!) {
  updateAssetPresentationTableSchema(input: $input) {
    schema
    errors {
      field
      messages
    }
  }
}
    `;
export type UpdateAssetPresentationTableSchemaMutationFn = Apollo.MutationFunction<UpdateAssetPresentationTableSchemaMutation, UpdateAssetPresentationTableSchemaMutationVariables>;

/**
 * __useUpdateAssetPresentationTableSchemaMutation__
 *
 * To run a mutation, you first call `useUpdateAssetPresentationTableSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetPresentationTableSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetPresentationTableSchemaMutation, { data, loading, error }] = useUpdateAssetPresentationTableSchemaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetPresentationTableSchemaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssetPresentationTableSchemaMutation, UpdateAssetPresentationTableSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssetPresentationTableSchemaMutation, UpdateAssetPresentationTableSchemaMutationVariables>(UpdateAssetPresentationTableSchemaDocument, options);
      }
export type UpdateAssetPresentationTableSchemaMutationHookResult = ReturnType<typeof useUpdateAssetPresentationTableSchemaMutation>;
export type UpdateAssetPresentationTableSchemaMutationResult = Apollo.MutationResult<UpdateAssetPresentationTableSchemaMutation>;
export type UpdateAssetPresentationTableSchemaMutationOptions = Apollo.BaseMutationOptions<UpdateAssetPresentationTableSchemaMutation, UpdateAssetPresentationTableSchemaMutationVariables>;
export const CreateDataReportDocument = gql`
    mutation CreateDataReport($input: CreateDataReportMutationInput!) {
  createDataReport(input: $input) {
    errors {
      ...Errors
    }
    dataReport {
      id
      name
      source
      sourceId
      createdAt
      updatedAt
      description
      schedule
    }
  }
}
    ${ErrorsFragmentDoc}`;
export type CreateDataReportMutationFn = Apollo.MutationFunction<CreateDataReportMutation, CreateDataReportMutationVariables>;

/**
 * __useCreateDataReportMutation__
 *
 * To run a mutation, you first call `useCreateDataReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataReportMutation, { data, loading, error }] = useCreateDataReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateDataReportMutation, CreateDataReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDataReportMutation, CreateDataReportMutationVariables>(CreateDataReportDocument, options);
      }
export type CreateDataReportMutationHookResult = ReturnType<typeof useCreateDataReportMutation>;
export type CreateDataReportMutationResult = Apollo.MutationResult<CreateDataReportMutation>;
export type CreateDataReportMutationOptions = Apollo.BaseMutationOptions<CreateDataReportMutation, CreateDataReportMutationVariables>;
export const UpdateDataReportDocument = gql`
    mutation UpdateDataReport($input: UpdateDataReportMutationInput!) {
  updateDataReport(input: $input) {
    errors {
      ...Errors
    }
    dataReport {
      id
      name
      sort
      source
      sourceId
      layout
      filters
      createdAt
      updatedAt
      description
    }
  }
}
    ${ErrorsFragmentDoc}`;
export type UpdateDataReportMutationFn = Apollo.MutationFunction<UpdateDataReportMutation, UpdateDataReportMutationVariables>;

/**
 * __useUpdateDataReportMutation__
 *
 * To run a mutation, you first call `useUpdateDataReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataReportMutation, { data, loading, error }] = useUpdateDataReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDataReportMutation, UpdateDataReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDataReportMutation, UpdateDataReportMutationVariables>(UpdateDataReportDocument, options);
      }
export type UpdateDataReportMutationHookResult = ReturnType<typeof useUpdateDataReportMutation>;
export type UpdateDataReportMutationResult = Apollo.MutationResult<UpdateDataReportMutation>;
export type UpdateDataReportMutationOptions = Apollo.BaseMutationOptions<UpdateDataReportMutation, UpdateDataReportMutationVariables>;
export const DeleteTaskDocument = gql`
    mutation DeleteTask($input: DeleteTaskMutationInput!) {
  deleteTask(input: $input) {
    task {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, options);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const CreateTaskDocument = gql`
    mutation CreateTask($input: CreateTaskMutationInput!) {
  createTask(input: $input) {
    task {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const PatchTaskDocument = gql`
    mutation PatchTask($input: PatchTaskMutationInput!) {
  patchTask(input: $input) {
    task {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;
export type PatchTaskMutationFn = Apollo.MutationFunction<PatchTaskMutation, PatchTaskMutationVariables>;

/**
 * __usePatchTaskMutation__
 *
 * To run a mutation, you first call `usePatchTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchTaskMutation, { data, loading, error }] = usePatchTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatchTaskMutation(baseOptions?: Apollo.MutationHookOptions<PatchTaskMutation, PatchTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchTaskMutation, PatchTaskMutationVariables>(PatchTaskDocument, options);
      }
export type PatchTaskMutationHookResult = ReturnType<typeof usePatchTaskMutation>;
export type PatchTaskMutationResult = Apollo.MutationResult<PatchTaskMutation>;
export type PatchTaskMutationOptions = Apollo.BaseMutationOptions<PatchTaskMutation, PatchTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation UpdateTask($input: UpdateTaskMutationInput!) {
  updateTask(input: $input) {
    task {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const AssetDocument = gql`
    query Asset($id: ID!) {
  asset(id: $id) {
    ...AssetDetails
    relatedData
  }
}
    ${AssetDetailsFragmentDoc}`;

/**
 * __useAssetQuery__
 *
 * To run a query within a React component, call `useAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetQuery(baseOptions: Apollo.QueryHookOptions<AssetQuery, AssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetQuery, AssetQueryVariables>(AssetDocument, options);
      }
export function useAssetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetQuery, AssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetQuery, AssetQueryVariables>(AssetDocument, options);
        }
export type AssetQueryHookResult = ReturnType<typeof useAssetQuery>;
export type AssetLazyQueryHookResult = ReturnType<typeof useAssetLazyQuery>;
export type AssetQueryResult = Apollo.QueryResult<AssetQuery, AssetQueryVariables>;
export const GetAssetDocument = gql`
    query getAsset($id: ID!) {
  asset(id: $id) {
    uid
    data
    createdAt
    updatedAt
    fleet {
      id
      uid
      name
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetAssetQuery__
 *
 * To run a query within a React component, call `useGetAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetQuery(baseOptions: Apollo.QueryHookOptions<GetAssetQuery, GetAssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetQuery, GetAssetQueryVariables>(GetAssetDocument, options);
      }
export function useGetAssetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetQuery, GetAssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetQuery, GetAssetQueryVariables>(GetAssetDocument, options);
        }
export type GetAssetQueryHookResult = ReturnType<typeof useGetAssetQuery>;
export type GetAssetLazyQueryHookResult = ReturnType<typeof useGetAssetLazyQuery>;
export type GetAssetQueryResult = Apollo.QueryResult<GetAssetQuery, GetAssetQueryVariables>;
export const AssetKindsDocument = gql`
    query AssetKinds($first: Int, $after: String, $hasSchema: Boolean) {
  assetKinds(first: $first, after: $after, hasSchema: $hasSchema) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        name
        value
        fontIcon
        fileIcon
        presentation {
          id
          table
          createdAt
          updatedAt
        }
        schema {
          id
          version
          createdAt
          updatedAt
          value
        }
        layout
      }
    }
  }
}
    `;

/**
 * __useAssetKindsQuery__
 *
 * To run a query within a React component, call `useAssetKindsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetKindsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetKindsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      hasSchema: // value for 'hasSchema'
 *   },
 * });
 */
export function useAssetKindsQuery(baseOptions?: Apollo.QueryHookOptions<AssetKindsQuery, AssetKindsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetKindsQuery, AssetKindsQueryVariables>(AssetKindsDocument, options);
      }
export function useAssetKindsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetKindsQuery, AssetKindsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetKindsQuery, AssetKindsQueryVariables>(AssetKindsDocument, options);
        }
export type AssetKindsQueryHookResult = ReturnType<typeof useAssetKindsQuery>;
export type AssetKindsLazyQueryHookResult = ReturnType<typeof useAssetKindsLazyQuery>;
export type AssetKindsQueryResult = Apollo.QueryResult<AssetKindsQuery, AssetKindsQueryVariables>;
export const AssetKindDocument = gql`
    query AssetKind($id: ID!) {
  assetKind(id: $id) {
    id
    name
    value
    fontIcon
    fileIcon
    presentation {
      id
      table
      createdAt
      updatedAt
    }
    schema {
      id
      version
      createdAt
      updatedAt
      value
    }
    layout
  }
}
    `;

/**
 * __useAssetKindQuery__
 *
 * To run a query within a React component, call `useAssetKindQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetKindQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetKindQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetKindQuery(baseOptions: Apollo.QueryHookOptions<AssetKindQuery, AssetKindQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetKindQuery, AssetKindQueryVariables>(AssetKindDocument, options);
      }
export function useAssetKindLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetKindQuery, AssetKindQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetKindQuery, AssetKindQueryVariables>(AssetKindDocument, options);
        }
export type AssetKindQueryHookResult = ReturnType<typeof useAssetKindQuery>;
export type AssetKindLazyQueryHookResult = ReturnType<typeof useAssetKindLazyQuery>;
export type AssetKindQueryResult = Apollo.QueryResult<AssetKindQuery, AssetKindQueryVariables>;
export const AssetsDocument = gql`
    query Assets($first: Int, $after: String, $kind: ID, $search: String, $filter: String, $orderBy: String) {
  assets(
    first: $first
    after: $after
    assetKind: $kind
    search: $search
    filter: $filter
    orderBy: $orderBy
  ) {
    totalCount
    pageInfo {
      ...Pagination
    }
    edges {
      node {
        ...BasicAssetListingInfo
      }
      cursor
    }
  }
}
    ${PaginationFragmentDoc}
${BasicAssetListingInfoFragmentDoc}`;

/**
 * __useAssetsQuery__
 *
 * To run a query within a React component, call `useAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      kind: // value for 'kind'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAssetsQuery(baseOptions?: Apollo.QueryHookOptions<AssetsQuery, AssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetsQuery, AssetsQueryVariables>(AssetsDocument, options);
      }
export function useAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetsQuery, AssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetsQuery, AssetsQueryVariables>(AssetsDocument, options);
        }
export type AssetsQueryHookResult = ReturnType<typeof useAssetsQuery>;
export type AssetsLazyQueryHookResult = ReturnType<typeof useAssetsLazyQuery>;
export type AssetsQueryResult = Apollo.QueryResult<AssetsQuery, AssetsQueryVariables>;
export const GetAssetsDocument = gql`
    query getAssets($first: Int, $after: String, $fleet: ID, $search: String) {
  assets(first: $first, after: $after, fleet: $fleet, search: $search) {
    edges {
      node {
        id
        uid
        data
        createdAt
        updatedAt
        fleet {
          id
          uid
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetAssetsQuery__
 *
 * To run a query within a React component, call `useGetAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      fleet: // value for 'fleet'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetAssetsQuery(baseOptions?: Apollo.QueryHookOptions<GetAssetsQuery, GetAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetsQuery, GetAssetsQueryVariables>(GetAssetsDocument, options);
      }
export function useGetAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetsQuery, GetAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetsQuery, GetAssetsQueryVariables>(GetAssetsDocument, options);
        }
export type GetAssetsQueryHookResult = ReturnType<typeof useGetAssetsQuery>;
export type GetAssetsLazyQueryHookResult = ReturnType<typeof useGetAssetsLazyQuery>;
export type GetAssetsQueryResult = Apollo.QueryResult<GetAssetsQuery, GetAssetsQueryVariables>;
export const ChartDashboardsDocument = gql`
    query ChartDashboards($first: Int, $after: String) {
  chartDashboards(first: $first, after: $after) {
    pageInfo {
      ...Pagination
    }
    edges {
      node {
        ...ChartDashboradFragment
      }
    }
  }
}
    ${PaginationFragmentDoc}
${ChartDashboradFragmentFragmentDoc}`;

/**
 * __useChartDashboardsQuery__
 *
 * To run a query within a React component, call `useChartDashboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartDashboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartDashboardsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useChartDashboardsQuery(baseOptions?: Apollo.QueryHookOptions<ChartDashboardsQuery, ChartDashboardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartDashboardsQuery, ChartDashboardsQueryVariables>(ChartDashboardsDocument, options);
      }
export function useChartDashboardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartDashboardsQuery, ChartDashboardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartDashboardsQuery, ChartDashboardsQueryVariables>(ChartDashboardsDocument, options);
        }
export type ChartDashboardsQueryHookResult = ReturnType<typeof useChartDashboardsQuery>;
export type ChartDashboardsLazyQueryHookResult = ReturnType<typeof useChartDashboardsLazyQuery>;
export type ChartDashboardsQueryResult = Apollo.QueryResult<ChartDashboardsQuery, ChartDashboardsQueryVariables>;
export const ChartDashboardDocument = gql`
    query ChartDashboard($id: ID!, $first: Int, $after: String) {
  chartDashboard(id: $id) {
    ...ChartDashboradFragment
    cards(first: $first, after: $after) {
      pageInfo {
        ...Pagination
      }
      edges {
        node {
          id
          title
          position
          charts {
            id
            source {
              id
              name
              layout
              dataStreamUrl
            }
            configuration
          }
        }
      }
    }
  }
}
    ${ChartDashboradFragmentFragmentDoc}
${PaginationFragmentDoc}`;

/**
 * __useChartDashboardQuery__
 *
 * To run a query within a React component, call `useChartDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartDashboardQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useChartDashboardQuery(baseOptions: Apollo.QueryHookOptions<ChartDashboardQuery, ChartDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartDashboardQuery, ChartDashboardQueryVariables>(ChartDashboardDocument, options);
      }
export function useChartDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartDashboardQuery, ChartDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartDashboardQuery, ChartDashboardQueryVariables>(ChartDashboardDocument, options);
        }
export type ChartDashboardQueryHookResult = ReturnType<typeof useChartDashboardQuery>;
export type ChartDashboardLazyQueryHookResult = ReturnType<typeof useChartDashboardLazyQuery>;
export type ChartDashboardQueryResult = Apollo.QueryResult<ChartDashboardQuery, ChartDashboardQueryVariables>;
export const ChartDashboardCardsDocument = gql`
    query ChartDashboardCards($dashboardId: ID!, $first: Int, $after: String) {
  chartDashboardCards(dashboard: $dashboardId, first: $first, after: $after) {
    totalCount
    pageInfo {
      ...Pagination
    }
    edges {
      node {
        id
        title
        position
        charts {
          id
          position
          source {
            id
            name
            layout
            dataStreamUrl
          }
          configuration
        }
        createdAt
        updatedAt
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useChartDashboardCardsQuery__
 *
 * To run a query within a React component, call `useChartDashboardCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartDashboardCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartDashboardCardsQuery({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useChartDashboardCardsQuery(baseOptions: Apollo.QueryHookOptions<ChartDashboardCardsQuery, ChartDashboardCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartDashboardCardsQuery, ChartDashboardCardsQueryVariables>(ChartDashboardCardsDocument, options);
      }
export function useChartDashboardCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartDashboardCardsQuery, ChartDashboardCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartDashboardCardsQuery, ChartDashboardCardsQueryVariables>(ChartDashboardCardsDocument, options);
        }
export type ChartDashboardCardsQueryHookResult = ReturnType<typeof useChartDashboardCardsQuery>;
export type ChartDashboardCardsLazyQueryHookResult = ReturnType<typeof useChartDashboardCardsLazyQuery>;
export type ChartDashboardCardsQueryResult = Apollo.QueryResult<ChartDashboardCardsQuery, ChartDashboardCardsQueryVariables>;
export const DataReportsDocument = gql`
    query DataReports($first: Int, $after: String, $name: String) {
  dataReports(first: $first, after: $after, name: $name) {
    totalCount
    pageInfo {
      ...Pagination
    }
    edges {
      node {
        id
        name
        source
        sourceId
        createdAt
        updatedAt
        user {
          email
        }
        snapshots(first: 1) {
          edges {
            node {
              createdAt
            }
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useDataReportsQuery__
 *
 * To run a query within a React component, call `useDataReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataReportsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDataReportsQuery(baseOptions?: Apollo.QueryHookOptions<DataReportsQuery, DataReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataReportsQuery, DataReportsQueryVariables>(DataReportsDocument, options);
      }
export function useDataReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataReportsQuery, DataReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataReportsQuery, DataReportsQueryVariables>(DataReportsDocument, options);
        }
export type DataReportsQueryHookResult = ReturnType<typeof useDataReportsQuery>;
export type DataReportsLazyQueryHookResult = ReturnType<typeof useDataReportsLazyQuery>;
export type DataReportsQueryResult = Apollo.QueryResult<DataReportsQuery, DataReportsQueryVariables>;
export const DataReportDocument = gql`
    query DataReport($id: ID!) {
  dataReport(id: $id) {
    id
    name
    source
    sourceId
    sort
    layout
    filters
    createdAt
    updatedAt
    schedule
    description
    user {
      email
    }
    snapshots(first: 1) {
      edges {
        node {
          createdAt
        }
      }
    }
  }
}
    `;

/**
 * __useDataReportQuery__
 *
 * To run a query within a React component, call `useDataReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDataReportQuery(baseOptions: Apollo.QueryHookOptions<DataReportQuery, DataReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataReportQuery, DataReportQueryVariables>(DataReportDocument, options);
      }
export function useDataReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataReportQuery, DataReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataReportQuery, DataReportQueryVariables>(DataReportDocument, options);
        }
export type DataReportQueryHookResult = ReturnType<typeof useDataReportQuery>;
export type DataReportLazyQueryHookResult = ReturnType<typeof useDataReportLazyQuery>;
export type DataReportQueryResult = Apollo.QueryResult<DataReportQuery, DataReportQueryVariables>;
export const DataReportSnapshotDocument = gql`
    query DataReportSnapshot($id: ID!) {
  dataReportSnapshot(id: $id) {
    id
    name
    source
    sourceId
    downloadUrl
    dataStreamUrl
    createdAt
    filters
    layout
    sort
    description
    template {
      id
    }
    user {
      email
    }
    label @client
    url @client
    dashboard {
      id
      name
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useDataReportSnapshotQuery__
 *
 * To run a query within a React component, call `useDataReportSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataReportSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataReportSnapshotQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDataReportSnapshotQuery(baseOptions: Apollo.QueryHookOptions<DataReportSnapshotQuery, DataReportSnapshotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataReportSnapshotQuery, DataReportSnapshotQueryVariables>(DataReportSnapshotDocument, options);
      }
export function useDataReportSnapshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataReportSnapshotQuery, DataReportSnapshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataReportSnapshotQuery, DataReportSnapshotQueryVariables>(DataReportSnapshotDocument, options);
        }
export type DataReportSnapshotQueryHookResult = ReturnType<typeof useDataReportSnapshotQuery>;
export type DataReportSnapshotLazyQueryHookResult = ReturnType<typeof useDataReportSnapshotLazyQuery>;
export type DataReportSnapshotQueryResult = Apollo.QueryResult<DataReportSnapshotQuery, DataReportSnapshotQueryVariables>;
export const DataReportSnapshotRowsDocument = gql`
    query DataReportSnapshotRows($first: Int, $after: String, $snapshotId: ID!) {
  dataReportSnapshotRows(first: $first, after: $after, snapshot: $snapshotId) {
    totalCount
    pageInfo {
      ...Pagination
    }
    edges {
      node {
        id
        data
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useDataReportSnapshotRowsQuery__
 *
 * To run a query within a React component, call `useDataReportSnapshotRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataReportSnapshotRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataReportSnapshotRowsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      snapshotId: // value for 'snapshotId'
 *   },
 * });
 */
export function useDataReportSnapshotRowsQuery(baseOptions: Apollo.QueryHookOptions<DataReportSnapshotRowsQuery, DataReportSnapshotRowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataReportSnapshotRowsQuery, DataReportSnapshotRowsQueryVariables>(DataReportSnapshotRowsDocument, options);
      }
export function useDataReportSnapshotRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataReportSnapshotRowsQuery, DataReportSnapshotRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataReportSnapshotRowsQuery, DataReportSnapshotRowsQueryVariables>(DataReportSnapshotRowsDocument, options);
        }
export type DataReportSnapshotRowsQueryHookResult = ReturnType<typeof useDataReportSnapshotRowsQuery>;
export type DataReportSnapshotRowsLazyQueryHookResult = ReturnType<typeof useDataReportSnapshotRowsLazyQuery>;
export type DataReportSnapshotRowsQueryResult = Apollo.QueryResult<DataReportSnapshotRowsQuery, DataReportSnapshotRowsQueryVariables>;
export const DataReportSnapshotsDocument = gql`
    query DataReportSnapshots($first: Int, $after: String, $template: ID) {
  dataReportSnapshots(first: $first, after: $after, template: $template) {
    totalCount
    pageInfo {
      ...Pagination
    }
    edges {
      node {
        id
        name
        source
        sourceId
        createdAt
        label @client
        url @client
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useDataReportSnapshotsQuery__
 *
 * To run a query within a React component, call `useDataReportSnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataReportSnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataReportSnapshotsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      template: // value for 'template'
 *   },
 * });
 */
export function useDataReportSnapshotsQuery(baseOptions?: Apollo.QueryHookOptions<DataReportSnapshotsQuery, DataReportSnapshotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataReportSnapshotsQuery, DataReportSnapshotsQueryVariables>(DataReportSnapshotsDocument, options);
      }
export function useDataReportSnapshotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataReportSnapshotsQuery, DataReportSnapshotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataReportSnapshotsQuery, DataReportSnapshotsQueryVariables>(DataReportSnapshotsDocument, options);
        }
export type DataReportSnapshotsQueryHookResult = ReturnType<typeof useDataReportSnapshotsQuery>;
export type DataReportSnapshotsLazyQueryHookResult = ReturnType<typeof useDataReportSnapshotsLazyQuery>;
export type DataReportSnapshotsQueryResult = Apollo.QueryResult<DataReportSnapshotsQuery, DataReportSnapshotsQueryVariables>;
export const InvokeDataReportDocument = gql`
    mutation InvokeDataReport($input: InvokeDataReportInput!) {
  invokeDataReport(input: $input) {
    errors {
      field
      messages
    }
    snapshot {
      id
      name
      createdAt
    }
  }
}
    `;
export type InvokeDataReportMutationFn = Apollo.MutationFunction<InvokeDataReportMutation, InvokeDataReportMutationVariables>;

/**
 * __useInvokeDataReportMutation__
 *
 * To run a mutation, you first call `useInvokeDataReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvokeDataReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invokeDataReportMutation, { data, loading, error }] = useInvokeDataReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvokeDataReportMutation(baseOptions?: Apollo.MutationHookOptions<InvokeDataReportMutation, InvokeDataReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvokeDataReportMutation, InvokeDataReportMutationVariables>(InvokeDataReportDocument, options);
      }
export type InvokeDataReportMutationHookResult = ReturnType<typeof useInvokeDataReportMutation>;
export type InvokeDataReportMutationResult = Apollo.MutationResult<InvokeDataReportMutation>;
export type InvokeDataReportMutationOptions = Apollo.BaseMutationOptions<InvokeDataReportMutation, InvokeDataReportMutationVariables>;
export const DeleteDataReportDocument = gql`
    mutation DeleteDataReport($input: DeleteDataReportInput!) {
  deleteDataReport(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteDataReportMutationFn = Apollo.MutationFunction<DeleteDataReportMutation, DeleteDataReportMutationVariables>;

/**
 * __useDeleteDataReportMutation__
 *
 * To run a mutation, you first call `useDeleteDataReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataReportMutation, { data, loading, error }] = useDeleteDataReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDataReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDataReportMutation, DeleteDataReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDataReportMutation, DeleteDataReportMutationVariables>(DeleteDataReportDocument, options);
      }
export type DeleteDataReportMutationHookResult = ReturnType<typeof useDeleteDataReportMutation>;
export type DeleteDataReportMutationResult = Apollo.MutationResult<DeleteDataReportMutation>;
export type DeleteDataReportMutationOptions = Apollo.BaseMutationOptions<DeleteDataReportMutation, DeleteDataReportMutationVariables>;
export const GetDatapointDocument = gql`
    query getDatapoint($id: ID!) {
  datapoint(id: $id) {
    id
    kind
    data
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetDatapointQuery__
 *
 * To run a query within a React component, call `useGetDatapointQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatapointQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatapointQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDatapointQuery(baseOptions: Apollo.QueryHookOptions<GetDatapointQuery, GetDatapointQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatapointQuery, GetDatapointQueryVariables>(GetDatapointDocument, options);
      }
export function useGetDatapointLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatapointQuery, GetDatapointQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatapointQuery, GetDatapointQueryVariables>(GetDatapointDocument, options);
        }
export type GetDatapointQueryHookResult = ReturnType<typeof useGetDatapointQuery>;
export type GetDatapointLazyQueryHookResult = ReturnType<typeof useGetDatapointLazyQuery>;
export type GetDatapointQueryResult = Apollo.QueryResult<GetDatapointQuery, GetDatapointQueryVariables>;
export const DataPointsDocument = gql`
    query DataPoints($first: Int, $after: String, $asset: ID, $provider: ID) {
  datapoints(first: $first, after: $after, asset: $asset, provider: $provider) {
    pageInfo {
      ...Pagination
    }
    edges {
      node {
        id
        provider {
          id
          name
        }
        data
        timestamp
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useDataPointsQuery__
 *
 * To run a query within a React component, call `useDataPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataPointsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      asset: // value for 'asset'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useDataPointsQuery(baseOptions?: Apollo.QueryHookOptions<DataPointsQuery, DataPointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataPointsQuery, DataPointsQueryVariables>(DataPointsDocument, options);
      }
export function useDataPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataPointsQuery, DataPointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataPointsQuery, DataPointsQueryVariables>(DataPointsDocument, options);
        }
export type DataPointsQueryHookResult = ReturnType<typeof useDataPointsQuery>;
export type DataPointsLazyQueryHookResult = ReturnType<typeof useDataPointsLazyQuery>;
export type DataPointsQueryResult = Apollo.QueryResult<DataPointsQuery, DataPointsQueryVariables>;
export const GetDatapointsDocument = gql`
    query getDatapoints($first: Int, $after: String) {
  datapoints(first: $first, after: $after) {
    edges {
      node {
        id
        data
        timestamp
        kind
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetDatapointsQuery__
 *
 * To run a query within a React component, call `useGetDatapointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatapointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatapointsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetDatapointsQuery(baseOptions?: Apollo.QueryHookOptions<GetDatapointsQuery, GetDatapointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatapointsQuery, GetDatapointsQueryVariables>(GetDatapointsDocument, options);
      }
export function useGetDatapointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatapointsQuery, GetDatapointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatapointsQuery, GetDatapointsQueryVariables>(GetDatapointsDocument, options);
        }
export type GetDatapointsQueryHookResult = ReturnType<typeof useGetDatapointsQuery>;
export type GetDatapointsLazyQueryHookResult = ReturnType<typeof useGetDatapointsLazyQuery>;
export type GetDatapointsQueryResult = Apollo.QueryResult<GetDatapointsQuery, GetDatapointsQueryVariables>;
export const AssetFilterValuesDocument = gql`
    query AssetFilterValues($input: AssetFilterValuesParams) {
  assetFilterValues(input: $input)
}
    `;

/**
 * __useAssetFilterValuesQuery__
 *
 * To run a query within a React component, call `useAssetFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetFilterValuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssetFilterValuesQuery(baseOptions?: Apollo.QueryHookOptions<AssetFilterValuesQuery, AssetFilterValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetFilterValuesQuery, AssetFilterValuesQueryVariables>(AssetFilterValuesDocument, options);
      }
export function useAssetFilterValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetFilterValuesQuery, AssetFilterValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetFilterValuesQuery, AssetFilterValuesQueryVariables>(AssetFilterValuesDocument, options);
        }
export type AssetFilterValuesQueryHookResult = ReturnType<typeof useAssetFilterValuesQuery>;
export type AssetFilterValuesLazyQueryHookResult = ReturnType<typeof useAssetFilterValuesLazyQuery>;
export type AssetFilterValuesQueryResult = Apollo.QueryResult<AssetFilterValuesQuery, AssetFilterValuesQueryVariables>;
export const PresentationDocument = gql`
    query Presentation($id: ID!) {
  presentation(id: $id) {
    id
    createdAt
    updatedAt
    table
    assetKind {
      id
      name
      value
      layout
      schema {
        id
        value
        target {
          id
          name
          value
        }
      }
    }
  }
}
    `;

/**
 * __usePresentationQuery__
 *
 * To run a query within a React component, call `usePresentationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresentationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresentationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePresentationQuery(baseOptions: Apollo.QueryHookOptions<PresentationQuery, PresentationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PresentationQuery, PresentationQueryVariables>(PresentationDocument, options);
      }
export function usePresentationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PresentationQuery, PresentationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PresentationQuery, PresentationQueryVariables>(PresentationDocument, options);
        }
export type PresentationQueryHookResult = ReturnType<typeof usePresentationQuery>;
export type PresentationLazyQueryHookResult = ReturnType<typeof usePresentationLazyQuery>;
export type PresentationQueryResult = Apollo.QueryResult<PresentationQuery, PresentationQueryVariables>;
export const PresentationsDocument = gql`
    query Presentations($first: Int, $after: String) {
  allPresentations(first: $first, after: $after) {
    edges {
      node {
        id
        table
        assetKind {
          id
          name
          value
          layout
          schema {
            id
            value
            target {
              id
              name
              value
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePresentationsQuery__
 *
 * To run a query within a React component, call `usePresentationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresentationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresentationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePresentationsQuery(baseOptions?: Apollo.QueryHookOptions<PresentationsQuery, PresentationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PresentationsQuery, PresentationsQueryVariables>(PresentationsDocument, options);
      }
export function usePresentationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PresentationsQuery, PresentationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PresentationsQuery, PresentationsQueryVariables>(PresentationsDocument, options);
        }
export type PresentationsQueryHookResult = ReturnType<typeof usePresentationsQuery>;
export type PresentationsLazyQueryHookResult = ReturnType<typeof usePresentationsLazyQuery>;
export type PresentationsQueryResult = Apollo.QueryResult<PresentationsQuery, PresentationsQueryVariables>;
export const ReportsDocument = gql`
    query Reports($first: Int) {
  allReports(first: $first) {
    pageInfo {
      ...Pagination
    }
    edges {
      node {
        id
        name
        file
        fileUri
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
      }
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
        }
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const ReportDocument = gql`
    query Report($id: ID!) {
  report(id: $id) {
    id
    name
    file
    fileUri
    config
    layout
  }
}
    `;

/**
 * __useReportQuery__
 *
 * To run a query within a React component, call `useReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportQuery(baseOptions: Apollo.QueryHookOptions<ReportQuery, ReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportQuery, ReportQueryVariables>(ReportDocument, options);
      }
export function useReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportQuery, ReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportQuery, ReportQueryVariables>(ReportDocument, options);
        }
export type ReportQueryHookResult = ReturnType<typeof useReportQuery>;
export type ReportLazyQueryHookResult = ReturnType<typeof useReportLazyQuery>;
export type ReportQueryResult = Apollo.QueryResult<ReportQuery, ReportQueryVariables>;
export const ReportRowsDocument = gql`
    query ReportRows($reportId: ID!, $first: Int, $after: String, $filter: String, $search: String, $orderBy: String, $fromDate: DateTime, $toDate: DateTime) {
  reportRows(
    report: $reportId
    first: $first
    after: $after
    filter: $filter
    search: $search
    orderBy: $orderBy
    fromDate: $fromDate
    toDate: $toDate
  ) {
    totalCount
    pageInfo {
      ...Pagination
    }
    edges {
      node {
        id
        data
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useReportRowsQuery__
 *
 * To run a query within a React component, call `useReportRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportRowsQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useReportRowsQuery(baseOptions: Apollo.QueryHookOptions<ReportRowsQuery, ReportRowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportRowsQuery, ReportRowsQueryVariables>(ReportRowsDocument, options);
      }
export function useReportRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportRowsQuery, ReportRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportRowsQuery, ReportRowsQueryVariables>(ReportRowsDocument, options);
        }
export type ReportRowsQueryHookResult = ReturnType<typeof useReportRowsQuery>;
export type ReportRowsLazyQueryHookResult = ReturnType<typeof useReportRowsLazyQuery>;
export type ReportRowsQueryResult = Apollo.QueryResult<ReportRowsQuery, ReportRowsQueryVariables>;
export const ReportFilterValuesDocument = gql`
    query ReportFilterValues($params: ReportFilterValuesParams) {
  reportFilterValues(input: $params)
  _debug {
    sql {
      sql
    }
    exceptions {
      stack
      message
      excType
    }
  }
}
    `;

/**
 * __useReportFilterValuesQuery__
 *
 * To run a query within a React component, call `useReportFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportFilterValuesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useReportFilterValuesQuery(baseOptions?: Apollo.QueryHookOptions<ReportFilterValuesQuery, ReportFilterValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportFilterValuesQuery, ReportFilterValuesQueryVariables>(ReportFilterValuesDocument, options);
      }
export function useReportFilterValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportFilterValuesQuery, ReportFilterValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportFilterValuesQuery, ReportFilterValuesQueryVariables>(ReportFilterValuesDocument, options);
        }
export type ReportFilterValuesQueryHookResult = ReturnType<typeof useReportFilterValuesQuery>;
export type ReportFilterValuesLazyQueryHookResult = ReturnType<typeof useReportFilterValuesLazyQuery>;
export type ReportFilterValuesQueryResult = Apollo.QueryResult<ReportFilterValuesQuery, ReportFilterValuesQueryVariables>;
export const ScheduleSnapshotDocument = gql`
    mutation ScheduleSnapshot($input: ScheduleDataReportSnapshotInput!) {
  scheduleDataReportSnapshot(input: $input) {
    dataReport {
      id
      name
      schedule
    }
  }
}
    `;
export type ScheduleSnapshotMutationFn = Apollo.MutationFunction<ScheduleSnapshotMutation, ScheduleSnapshotMutationVariables>;

/**
 * __useScheduleSnapshotMutation__
 *
 * To run a mutation, you first call `useScheduleSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleSnapshotMutation, { data, loading, error }] = useScheduleSnapshotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleSnapshotMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleSnapshotMutation, ScheduleSnapshotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleSnapshotMutation, ScheduleSnapshotMutationVariables>(ScheduleSnapshotDocument, options);
      }
export type ScheduleSnapshotMutationHookResult = ReturnType<typeof useScheduleSnapshotMutation>;
export type ScheduleSnapshotMutationResult = Apollo.MutationResult<ScheduleSnapshotMutation>;
export type ScheduleSnapshotMutationOptions = Apollo.BaseMutationOptions<ScheduleSnapshotMutation, ScheduleSnapshotMutationVariables>;
export const RemoveScheduleDocument = gql`
    mutation RemoveSchedule($input: RemoveDataReportSnapshotScheduleInput!) {
  removeDataReportSnapshotSchedule(input: $input) {
    dataReport {
      id
      name
      schedule
    }
  }
}
    `;
export type RemoveScheduleMutationFn = Apollo.MutationFunction<RemoveScheduleMutation, RemoveScheduleMutationVariables>;

/**
 * __useRemoveScheduleMutation__
 *
 * To run a mutation, you first call `useRemoveScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeScheduleMutation, { data, loading, error }] = useRemoveScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveScheduleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveScheduleMutation, RemoveScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveScheduleMutation, RemoveScheduleMutationVariables>(RemoveScheduleDocument, options);
      }
export type RemoveScheduleMutationHookResult = ReturnType<typeof useRemoveScheduleMutation>;
export type RemoveScheduleMutationResult = Apollo.MutationResult<RemoveScheduleMutation>;
export type RemoveScheduleMutationOptions = Apollo.BaseMutationOptions<RemoveScheduleMutation, RemoveScheduleMutationVariables>;
export const AssetSchemaDocument = gql`
    query AssetSchema($id: ID!) {
  assetSchema(id: $id) {
    ...AssetSchemaFields
  }
}
    ${AssetSchemaFieldsFragmentDoc}`;

/**
 * __useAssetSchemaQuery__
 *
 * To run a query within a React component, call `useAssetSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetSchemaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetSchemaQuery(baseOptions: Apollo.QueryHookOptions<AssetSchemaQuery, AssetSchemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetSchemaQuery, AssetSchemaQueryVariables>(AssetSchemaDocument, options);
      }
export function useAssetSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetSchemaQuery, AssetSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetSchemaQuery, AssetSchemaQueryVariables>(AssetSchemaDocument, options);
        }
export type AssetSchemaQueryHookResult = ReturnType<typeof useAssetSchemaQuery>;
export type AssetSchemaLazyQueryHookResult = ReturnType<typeof useAssetSchemaLazyQuery>;
export type AssetSchemaQueryResult = Apollo.QueryResult<AssetSchemaQuery, AssetSchemaQueryVariables>;
export const AssetSchemasDocument = gql`
    query AssetSchemas($first: Int, $after: String) {
  assetSchemas(first: $first, after: $after) {
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      node {
        ...AssetSchemaFields
      }
    }
  }
}
    ${AssetSchemaFieldsFragmentDoc}`;

/**
 * __useAssetSchemasQuery__
 *
 * To run a query within a React component, call `useAssetSchemasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetSchemasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetSchemasQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAssetSchemasQuery(baseOptions?: Apollo.QueryHookOptions<AssetSchemasQuery, AssetSchemasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetSchemasQuery, AssetSchemasQueryVariables>(AssetSchemasDocument, options);
      }
export function useAssetSchemasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetSchemasQuery, AssetSchemasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetSchemasQuery, AssetSchemasQueryVariables>(AssetSchemasDocument, options);
        }
export type AssetSchemasQueryHookResult = ReturnType<typeof useAssetSchemasQuery>;
export type AssetSchemasLazyQueryHookResult = ReturnType<typeof useAssetSchemasLazyQuery>;
export type AssetSchemasQueryResult = Apollo.QueryResult<AssetSchemasQuery, AssetSchemasQueryVariables>;
export const TaskDocument = gql`
    query Task($id: ID!) {
  task(id: $id) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskQuery(baseOptions: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
      }
export function useTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
        }
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;
export const TasksDocument = gql`
    query Tasks($id: ID!) {
  tasks {
    edges {
      node {
        ...TaskFragment
      }
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTasksQuery(baseOptions: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
      }
export function useTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
        }
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>;
export const TaskCommentDocument = gql`
    query TaskComment($id: ID!) {
  taskComment(id: $id) {
    comment
    task {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useTaskCommentQuery__
 *
 * To run a query within a React component, call `useTaskCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCommentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskCommentQuery(baseOptions: Apollo.QueryHookOptions<TaskCommentQuery, TaskCommentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskCommentQuery, TaskCommentQueryVariables>(TaskCommentDocument, options);
      }
export function useTaskCommentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskCommentQuery, TaskCommentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskCommentQuery, TaskCommentQueryVariables>(TaskCommentDocument, options);
        }
export type TaskCommentQueryHookResult = ReturnType<typeof useTaskCommentQuery>;
export type TaskCommentLazyQueryHookResult = ReturnType<typeof useTaskCommentLazyQuery>;
export type TaskCommentQueryResult = Apollo.QueryResult<TaskCommentQuery, TaskCommentQueryVariables>;
export const TaskCommentsDocument = gql`
    query TaskComments($id: ID!) {
  taskComments {
    edges {
      node {
        comment
        task {
          ...TaskFragment
        }
      }
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useTaskCommentsQuery__
 *
 * To run a query within a React component, call `useTaskCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskCommentsQuery(baseOptions: Apollo.QueryHookOptions<TaskCommentsQuery, TaskCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskCommentsQuery, TaskCommentsQueryVariables>(TaskCommentsDocument, options);
      }
export function useTaskCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskCommentsQuery, TaskCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskCommentsQuery, TaskCommentsQueryVariables>(TaskCommentsDocument, options);
        }
export type TaskCommentsQueryHookResult = ReturnType<typeof useTaskCommentsQuery>;
export type TaskCommentsLazyQueryHookResult = ReturnType<typeof useTaskCommentsLazyQuery>;
export type TaskCommentsQueryResult = Apollo.QueryResult<TaskCommentsQuery, TaskCommentsQueryVariables>;
export const UserDocument = gql`
    query User($id: ID!) {
  user(id: $id) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    edges {
      node {
        ...UserFragment
      }
    }
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;