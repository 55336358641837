import { FC } from 'react';
import { BaseLayoutProps } from '@/lib/layouts/types';
import { Breadcrumbs } from '@/lib/components/app/Breadcrumbs';

export const BlankLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <>
      <Breadcrumbs />
      {children}
    </>
  );
};
